/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IMaskInput } from 'react-imask';
import styled from 'styled-components';
import ContainerSkeleton from '../sceleton/ContainerSceleton';
import merge from 'lodash.merge';
import moment from 'moment';
import { selectVariableData, selectLocalVariableData } from '../../store/page/selectors';
import { formValuesRequest, submitFormRequest, mergeStyleRequest, pageRequest, updateVariableRequest } from '../../store/page/actions';
import { combineClassNames, replacePlaceholdersWithVariablesV2, handleOpenUrl, getSizesBasedOnScreen, executeFunction, getNestedValue } from '../../utils/base';
import {
  TextInput, TextAreaInput, PasswordInput, EmailInput, NumberInput, FileInput, PhoneInput, DateTimePickerInput,
  StyleUpdateChunk, RequestUpdateChunk, FunctionUpdateChunk,
  Breakpoints,
} from '../../types/base';
import DateRangePicker from '../DateRangePicker';
import generateComponentStyles from '../../utils/styleHelper';

const validateInputPattern = (value: string, pattern?: string, patternFlag?: string): boolean => {
  if (!value || typeof value !== 'string') return false;
  if (!pattern) return true;
  
  try {
    const regex = patternFlag 
      ? new RegExp(pattern, patternFlag)
      : new RegExp(pattern);
      
    return regex.test(value);
  } catch (e) {
    console.error('Invalid regex pattern or flag:', { pattern, patternFlag, error: e });
    return false; // Fail closed for invalid patterns
  }
};

const StyledInput = styled.input`
    z-index: 1;
  ${(props) => generateComponentStyles(props.$shape, props.$baseScreenResolution, props.$skipStates)}
`;

// trigger

const StyledTextarea = styled.textarea`
  resize: none;
  z-index: 1;
  ${(props) => generateComponentStyles(props.$shape, props.$baseScreenResolution, props.$skipStates)}
`;

interface TextInputType extends TextInput {
  dataLake?: any;
  nestedTable?: boolean;
  parentIndex?: number;
  styleChunks?: any;
  pageSettings?: any;
}

interface PasswordInputType extends PasswordInput {
  dataLake?: any;
  nestedTable?: boolean;
  parentIndex?: number;
  styleChunks?: any;
  pageSettings?: any;
}

interface NumberInputType extends NumberInput {
  dataLake?: any;
  nestedTable?: boolean;
  parentIndex?: number;
  styleChunks?: any;
  pageSettings?: any;
}

interface TextAreaInputType extends TextAreaInput {
  dataLake?: any;
  nestedTable?: boolean;
  parentIndex?: number;
  styleChunks?: any;
  pageSettings?: any;
}

const Input: React.FC<TextInputType | PasswordInputType  | NumberInputType> = ({
  id, style: stDraft, placeholder,
  value,
  tooltip,
  events: eventsDraft,
  soc,
  pageSettings,
  dataLake,
  activeState: activeStateDraft,
  regexp,
  settings,
  type,
  nestedTable,
  parentIndex,
  styleChunks,
  sceleton: renderSceleton,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const variableData = useSelector(selectVariableData);
  const localVariableData = useSelector(selectLocalVariableData);
  const { delay = 500 } = settings || {};
  const { style: styleDraft, events, activeState } = dataLake?.data ? replacePlaceholdersWithVariablesV2({ style: stDraft, events: eventsDraft, activeState: activeStateDraft }, dataLake.data) : { style: stDraft, events: eventsDraft, activeState: activeStateDraft };
  const mergeStylesWithChunks = useCallback((style: any, chunks: any, index: number) => {
    if (chunks && chunks[id] && chunks[id][index] && typeof index === 'number') {
      return merge({}, style, chunks[id][index]);
    }
    return style;
  }, [id]);
  // First useMemo to merge active state styles with base style
  const mergedStyleWithState = useMemo(() => {
    let mergedStyle = styleDraft;
    if (activeState && styleDraft?.states?.[activeState]) {
      mergedStyle = merge({}, styleDraft, styleDraft.states[activeState]);
    }

    // Handle states in breakpoints
    if (styleDraft?.breakpoints) {
      mergedStyle.breakpoints = Object.entries(styleDraft.breakpoints).reduce((acc, [breakpoint, breakpointStyle]) => {
        const typedBreakpointStyle = breakpointStyle as Breakpoints;
        if (typedBreakpointStyle?.states?.[activeState]) {
          acc[breakpoint] = merge({}, typedBreakpointStyle, typedBreakpointStyle.states[activeState]);
        } else {
          acc[breakpoint] = typedBreakpointStyle;
        }
        return acc;
      }, {} as typeof styleDraft.breakpoints);
    }

    return mergedStyle;
  }, [styleDraft, activeState]);

  // Second useMemo to merge styles with chunks based on parentIndex
  const style = useMemo(() => {
    return mergeStylesWithChunks(mergedStyleWithState, styleChunks, parentIndex);
  }, [mergedStyleWithState, styleChunks, parentIndex]);
  const baseScreenResolution =  pageSettings?.baseScreenResolution ? getSizesBasedOnScreen(pageSettings?.baseScreenResolution) : undefined;
  const [internalValue, setInternalValue] = useState<string>(value);
  const { autofocus } = style || {};
  const dispatch = useDispatch();

  const internalFns = {
    $setVariable: (key: string, value: any) => {
      dispatch(updateVariableRequest({ variable: key, value }));
    },
    $getVariable: (key: string) => {
      const value = getNestedValue(variableData, key) || getNestedValue(localVariableData, key);
      return value;
    },
    $moment: (...args: any[]) => moment(...args)
  };
  const timeoutsRef = useRef<NodeJS.Timeout[]>([]);
  const { 
    on_focus: focusStyles, 
    on_change: changeStyles, 
    on_blur: blurStyles, 
    on_launch: launchUpdates,
    on_hover: hoverUpdates,
    on_unhover: unhoverUpdates,
    on_regexp_success: regexpSuccessUpdates,
    on_regexp_error: regexpErrorUpdates,
    on_outclick: outclickUpdates,
  } = events || {};

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  useEffect(() => {
    if (value !== internalValue) {
      setInternalValue(value);
    }
  }, [value]);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (activeState === 'disabled' || activeState === 'loading') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    dispatch(submitFormRequest({ block: true }));

    const newValue = e.target.value;

        // Check max length
    if (regexp?.max_length && newValue.length > regexp.max_length) {
      return;
    }
    setInternalValue(newValue);
    dispatch(formValuesRequest({ field: { id, value: newValue } }));
    // Check pattern validation
    if (changeStyles) {
      const styleUpdates = changeStyles.filter((update): update is StyleUpdateChunk => update && 'style' in update);
      const requestUpdates = changeStyles.filter((update): update is RequestUpdateChunk => update && 'request' in update);
      const functionUpdates = changeStyles.filter((update): update is FunctionUpdateChunk => update && 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'command') {
            dispatch(pageRequest({ 
              location: { pathname: value }, 
              silent,
              componentId: id, 
            }));
          } else if (update?.request && method === 'action') {
            dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }
      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, e, internalFns);
          }
        });
      }
    }
    if (regexp?.pattern && typeof regexp.pattern === 'string') {
      const result = validateInputPattern(newValue, regexp.pattern);
      if (regexpErrorUpdates && !result) {
          const styleUpdates = regexpErrorUpdates.filter((update): update is StyleUpdateChunk => update && 'style' in update);
          const requestUpdates = regexpErrorUpdates.filter((update): update is RequestUpdateChunk => update && 'request' in update);
          const functionUpdates = regexpErrorUpdates.filter((update): update is FunctionUpdateChunk => update && 'function' in update);
          if (styleUpdates.length) {
            dispatch(mergeStyleRequest(styleUpdates));
          }
          if (requestUpdates.length) {
            requestUpdates.forEach((update) => {
              const { method, value, silent, config } = update?.request || {};
              const { delay } = config || {};
              if (update?.request && method === 'command') {
                dispatch(pageRequest({ 
                  location: { pathname: value }, 
                  silent,
                  componentId: id,
                }));
              } else if (update?.request && method === 'action') {
                dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
              } else if (update?.request && method === 'open-url') {
                handleOpenUrl(value, delay, silent);
              }
            });
          }
          if (functionUpdates.length) {
            functionUpdates.forEach((update) => {
              if (update?.function) {
                executeFunction(update.function, e, internalFns);
              }
            });
          }
      } else if (regexpSuccessUpdates && result) {
          const styleUpdates = regexpSuccessUpdates.filter((update): update is StyleUpdateChunk => update && 'style' in update);
          const requestUpdates = regexpSuccessUpdates.filter((update): update is RequestUpdateChunk => update && 'request' in update);
          const functionUpdates = regexpSuccessUpdates.filter((update): update is FunctionUpdateChunk => update && 'function' in update);
          if (styleUpdates.length) {
            dispatch(mergeStyleRequest(styleUpdates));
          }
          if (requestUpdates.length) {
            requestUpdates.forEach((update) => {
              const { method, value, silent, config } = update?.request || {};
              const { delay } = config || {};
              if (update?.request && method === 'command') {
                dispatch(pageRequest({ 
                  location: { pathname: value }, 
                  silent,
                  componentId: id, 
                }));
              } else if (update?.request && method === 'action') {
                dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
              } else if (update?.request && method === 'open-url') {
                handleOpenUrl(value, delay, silent);
              }
            });
          }
          if (functionUpdates.length) {
            functionUpdates.forEach((update) => {
              if (update?.function) {
                executeFunction(update.function, e, internalFns);
              }
            });
          }
      }
    }
    if (soc) {
      dispatch(submitFormRequest({ value: 'soc', delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (activeState === 'disabled' || activeState === 'loading') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
  }

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (activeState === 'disabled' || activeState === 'loading') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (focusStyles) {
      const styleUpdates = focusStyles.filter((update): update is StyleUpdateChunk => update && 'style' in update);
      const requestUpdates = focusStyles.filter((update): update is RequestUpdateChunk => update && 'request' in update);
      const functionUpdates = focusStyles.filter((update): update is FunctionUpdateChunk => update && 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'command') {
            dispatch(pageRequest({ 
              location: { pathname: value },
               silent,
              componentId: id, 
              }));
          } else if (update?.request && method === 'action') {
            dispatch(submitFormRequest(
              { value, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }
      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, e, internalFns);
          }
        });
      }
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (activeState === 'disabled' || activeState === 'loading') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (blurStyles) {
      const styleUpdates = blurStyles.filter((update): update is StyleUpdateChunk => update && 'style' in update);
      const requestUpdates = blurStyles.filter((update): update is RequestUpdateChunk => update && 'request' in update);
      const functionUpdates = blurStyles.filter((update): update is FunctionUpdateChunk => update && 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'command') {
            dispatch(pageRequest({ 
              location: { pathname: value }, 
              silent,
              componentId: id,
            }));
          } else if (update?.request && method === 'action') {
            dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }
      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, e, internalFns);
          }
        });
      }
    }
  };

  const handleHover = (e: React.MouseEvent<HTMLInputElement>) => {
    if(activeState === 'disabled' || activeState === 'loading') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (hoverUpdates) {
      const styleUpdates = hoverUpdates.filter((update): update is StyleUpdateChunk => update && 'style' in update);
      const requestUpdates = hoverUpdates.filter((update): update is RequestUpdateChunk => update && 'request' in update);
      const functionUpdates = hoverUpdates.filter((update): update is FunctionUpdateChunk => update && 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'action') {
            dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }
      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, e, internalFns);
          }
        });
      }
    }
  
  }

  const handleUnhover = (e: React.MouseEvent<HTMLInputElement>) => {
    if(activeState === 'disabled' || activeState === 'loading') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (unhoverUpdates) {
      const styleUpdates = unhoverUpdates.filter((update): update is StyleUpdateChunk => update && 'style' in update);
      const requestUpdates = unhoverUpdates.filter((update): update is RequestUpdateChunk => update && 'request' in update);
      const functionUpdates = unhoverUpdates.filter((update): update is FunctionUpdateChunk => update && 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'action') {
            dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }
      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, e, internalFns);
          }
        });
      }
    }
  }

  useEffect(() => {
    if (launchUpdates && launchUpdates.length) {
      const requestUpdates = launchUpdates.filter((update): update is RequestUpdateChunk => update && 'request' in update);
      const functionUpdates = launchUpdates.filter((update): update is FunctionUpdateChunk => update && 'function' in update);
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'action') {
            // const newTimeout = setTimeout(() => {
            //   dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
            // }, config?.delay * 1000 || 0);

            // timeoutsRef.current.push(newTimeout);
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }

      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, undefined, internalFns);
          }
        });
      }
    }

    return () => {
      timeoutsRef?.current?.forEach((timeout) => {
        clearTimeout(timeout);
      });
    };
  }, [launchUpdates]);

  useEffect(() => {
    const handleUnclick = (e: MouseEvent) => {
      if (activeState === 'disabled' || activeState === 'loading') return;
  
      if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
        if (outclickUpdates) {
          const styleUpdates = outclickUpdates.filter((update): update is StyleUpdateChunk => update && 'style' in update);
          const requestUpdates = outclickUpdates.filter((update): update is RequestUpdateChunk => update && 'request' in update);
          const functionUpdates = outclickUpdates.filter((update): update is FunctionUpdateChunk => update && 'function' in update);
  
          if (styleUpdates.length) {
            dispatch(mergeStyleRequest(styleUpdates, parentIndex));
          }
  
          if (requestUpdates.length) {
            requestUpdates.forEach((update) => {
              const { method, value, silent, config } = update?.request || {};
              const { delay } = config || {};
              if (update?.request && method === 'action') {
                dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
              } else if (update?.request && method === 'open-url') {
                handleOpenUrl(value, delay, silent);
              }
            });
          }
  
          if (functionUpdates.length) {
            functionUpdates.forEach((update) => {
              if (update?.function) {
                executeFunction(update.function, undefined, internalFns);
              }
            });
          }
        }
      }
    };
  
    if (outclickUpdates) {
      document.addEventListener('mousedown', handleUnclick);
      return () => {
        document.removeEventListener('mousedown', handleUnclick);
      };
    }
    return undefined;
  }, [parentIndex, activeState, inputRef.current]);

  if (renderSceleton && style) {
    return <ContainerSkeleton 
      style={style}
      baseScreenResolution={baseScreenResolution}
      skipStates={activeState === 'disabled' || activeState === 'loading'}
    />;
  }

  return (
  // <Wrapper $shape={style}>
  // {activeBackground?.background && Array.isArray(activeBackground?.background) ? activeBackground.background.map((bg) => (
  //   <MainLayerController
  //     options={{ 
  //       borderRadius: activeBackground.borderRadius,
  //       borderWidth: activeBackground.borderWidth,
  //      }}
  //     key={uuidv4()}
  //     {...bg}
  //   />
  // )) : null}
// Inside your Input component, modify the return statement:
  regexp?.mask_pattern ? (
    <StyledInput
      as={IMaskInput}
      mask={regexp.mask_pattern}
      value={internalValue}
      onAccept={(value) => handleInput({ target: { value } } as unknown as React.ChangeEvent<HTMLInputElement>)}
      onKeyDown={handleKeyDown}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onMouseEnter={handleHover}
      onMouseLeave={handleUnhover}
      disabled={activeState === 'disabled'}
      inputRef={inputRef}
      placeholder={placeholder}
      id={`${id}${(nestedTable && typeof parentIndex === 'number') ? `::${parentIndex}` : ''}`}
      type={type || 'text'}
      style={{ display: style?.visibility === 'hidden' ? 'none' : undefined }}
      autoFocus={autofocus}
      maxLength={regexp?.max_length}
      onClick={(e) => {
        e.stopPropagation();
      }}
      $shape={style}
      $baseScreenResolution={baseScreenResolution}
      $skipStates={activeState === 'disabled' || activeState === 'loading'}
      data-component="input"
      className={combineClassNames(style.class)}
      title={tooltip}
      onInput={handleInput}
    />
  ) : (
    <StyledInput
      ref={inputRef}
      style={{ display: style?.visibility === 'hidden' ? 'none' : undefined }}
      placeholder={placeholder}
      id={`${id}${(nestedTable && typeof parentIndex === 'number') ? `::${parentIndex}` : ''}`}
      type={type || 'text'}
      $shape={style}
      $baseScreenResolution={baseScreenResolution}
      $skipStates={activeState === 'disabled' || activeState === 'loading'}
      disabled={activeState === 'disabled'}
      data-component="input"
      className={combineClassNames(style.class)}
      value={internalValue}
      title={tooltip}
      autoFocus={autofocus}
      onInput={handleInput}
      onClick={(e) => e.stopPropagation()}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onMouseEnter={handleHover}
      onMouseLeave={handleUnhover}
      maxLength={regexp?.max_length}
    />
  )
);
};

const TextArea: React.FC<TextAreaInputType> = ({
  id, style: stDraft, placeholder,
  value,
  tooltip,
  events: eventsDraft,
  soc,
  pageSettings,
  dataLake,
  activeState: activeStateDraft,
  regexp,
  settings,
  nestedTable,
  parentIndex,
  styleChunks,
  sceleton: renderSceleton,
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { delay = 500 } = settings || {};
  const variableData = useSelector(selectVariableData);
  const localVariableData = useSelector(selectLocalVariableData);
  const { style: styleDraft, events, activeState } = dataLake?.data ? replacePlaceholdersWithVariablesV2({ style: stDraft, events: eventsDraft, activeState: activeStateDraft}, dataLake.data) : { style: stDraft, events: eventsDraft, activeState: activeStateDraft };
  const mergeStylesWithChunks = useCallback((style: any, chunks: any, index: number) => {
    if (chunks && chunks[id] && chunks[id][index] && typeof index === 'number') {
      return merge({}, style, chunks[id][index]);
    }
    return style;
  }, [id]);
  // First useMemo to merge active state styles with base style
  const mergedStyleWithState = useMemo(() => {
    let mergedStyle = styleDraft;
    if (activeState && styleDraft?.states?.[activeState]) {
      mergedStyle = merge({}, styleDraft, styleDraft.states[activeState]);
    }

    // Handle states in breakpoints
    if (styleDraft?.breakpoints) {
      mergedStyle.breakpoints = Object.entries(styleDraft.breakpoints).reduce((acc, [breakpoint, breakpointStyle]) => {
        const typedBreakpointStyle = breakpointStyle as Breakpoints;
        if (typedBreakpointStyle?.states?.[activeState]) {
          acc[breakpoint] = merge({}, typedBreakpointStyle, typedBreakpointStyle.states[activeState]);
        } else {
          acc[breakpoint] = typedBreakpointStyle;
        }
        return acc;
      }, {} as typeof styleDraft.breakpoints);
    }

    return mergedStyle;
  }, [styleDraft, activeState]);

  // Second useMemo to merge styles with chunks based on parentIndex
  const style = useMemo(() => {
    return mergeStylesWithChunks(mergedStyleWithState, styleChunks, parentIndex);
  }, [mergedStyleWithState, styleChunks, parentIndex]);
  const baseScreenResolution =  pageSettings?.baseScreenResolution ? getSizesBasedOnScreen(pageSettings?.baseScreenResolution) : undefined;
  const [internalValue, setInternalValue] = useState<string>(value);
  const { autofocus } = style || {};
  const dispatch = useDispatch();

  const internalFns = {
    $setVariable: (key: string, value: any) => {
      dispatch(updateVariableRequest({ variable: key, value }));
    },
    $getVariable: (key: string) => {
      const value = getNestedValue(variableData, key) || getNestedValue(localVariableData, key);
      return value;
    },
    $moment: (...args: any[]) => moment(...args)
  };
  const timeoutsRef = useRef<NodeJS.Timeout[]>([]);
  const { 
    on_focus: focusStyles, 
    on_change: changeStyles, 
    on_blur: blurStyles, 
    on_launch: launchUpdates,
    on_hover: hoverUpdates,
    on_unhover: unhoverUpdates,
    on_regexp_success: regexpSuccessUpdates,
    on_regexp_error: regexpErrorUpdates,
    on_outclick: outclickUpdates,
  } = events || {};

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  useEffect(() => {
    if (value !== internalValue) {
      setInternalValue(value);
    }
  }, [value]);

  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (activeState === 'disabled' || activeState === 'loading') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    dispatch(submitFormRequest({ block: true }));

    const newValue = e.target.value;

        // Check max length
    if (regexp?.max_length && newValue.length > regexp.max_length) {
      return;
    }
    setInternalValue(newValue);
    dispatch(formValuesRequest({ field: { id, value: newValue } }));
    // Check pattern validation
    if (changeStyles) {
      const styleUpdates = changeStyles.filter((update): update is StyleUpdateChunk => update && 'style' in update);
      const requestUpdates = changeStyles.filter((update): update is RequestUpdateChunk => update && 'request' in update);
      const functionUpdates = changeStyles.filter((update): update is FunctionUpdateChunk => update && 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'command') {
            dispatch(pageRequest({ 
              location: { pathname: value }, 
              silent,
              componentId: id, 
            }));
          } else if (update?.request && method === 'action') {
            dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }
      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, e, internalFns);
          }
        });
      }
    }
    if (regexp?.pattern && typeof regexp.pattern === 'string') {
      const result = validateInputPattern(newValue, regexp.pattern, 'gs');
      if (regexpErrorUpdates && !result) {
          const styleUpdates = regexpErrorUpdates.filter((update): update is StyleUpdateChunk => update && 'style' in update);
          const requestUpdates = regexpErrorUpdates.filter((update): update is RequestUpdateChunk => update && 'request' in update);
          const functionUpdates = regexpErrorUpdates.filter((update): update is FunctionUpdateChunk => update && 'function' in update);
          if (styleUpdates.length) {
            dispatch(mergeStyleRequest(styleUpdates));
          }
          if (requestUpdates.length) {
            requestUpdates.forEach((update) => {
              const { method, value, silent, config } = update?.request || {};
              const { delay } = config || {};
              if (update?.request && method === 'command') {
                dispatch(pageRequest({ 
                  location: { pathname: value }, 
                  silent,
                  componentId: id,
                }));
              } else if (update?.request && method === 'action') {
                dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
              } else if (update?.request && method === 'open-url') {
                handleOpenUrl(value, delay, silent);
              }
            });
          }
          if (functionUpdates.length) {
            functionUpdates.forEach((update) => {
              if (update?.function) {
                executeFunction(update.function, e, internalFns);
              }
            });
          }
      } else if (regexpSuccessUpdates && result) {
          const styleUpdates = regexpSuccessUpdates.filter((update): update is StyleUpdateChunk => update && 'style' in update);
          const requestUpdates = regexpSuccessUpdates.filter((update): update is RequestUpdateChunk => update && 'request' in update);
          const functionUpdates = regexpSuccessUpdates.filter((update): update is FunctionUpdateChunk => update && 'function' in update);
          if (styleUpdates.length) {
            dispatch(mergeStyleRequest(styleUpdates));
          }
          if (requestUpdates.length) {
            requestUpdates.forEach((update) => {
              const { method, value, silent, config } = update?.request || {};
              const { delay } = config || {};
              if (update?.request && method === 'command') {
                dispatch(pageRequest({ 
                  location: { pathname: value }, 
                  silent,
                  componentId: id, 
                }));
              } else if (update?.request && method === 'action') {
                dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
              } else if (update?.request && method === 'open-url') {
                handleOpenUrl(value, delay, silent);
              }
            });
          }
          if (functionUpdates.length) {
            functionUpdates.forEach((update) => {
              if (update?.function) {
                executeFunction(update.function, e, internalFns);
              }
            });
          }
      }
    }
    if (soc) {
      dispatch(submitFormRequest({ value: 'soc', delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (activeState === 'disabled' || activeState === 'loading') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (focusStyles) {
      const styleUpdates = focusStyles.filter((update): update is StyleUpdateChunk => update && 'style' in update);
      const requestUpdates = focusStyles.filter((update): update is RequestUpdateChunk => update && 'request' in update);
      const functionUpdates = focusStyles.filter((update): update is FunctionUpdateChunk => update && 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'command') {
            dispatch(pageRequest({ 
              location: { pathname: value }, 
              silent,
              componentId: id 
            }));
          } else if (update?.request && method === 'action') {
            dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }
      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, e, internalFns);
          }
        });
      }
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (activeState === 'disabled' || activeState === 'loading') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (blurStyles) {
      const styleUpdates = blurStyles.filter((update): update is StyleUpdateChunk => update && 'style' in update);
      const requestUpdates = blurStyles.filter((update): update is RequestUpdateChunk => update && 'request' in update);
      const functionUpdates = blurStyles.filter((update): update is FunctionUpdateChunk => update && 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'command') {
            dispatch(pageRequest({ 
              location: { pathname: value }, 
              silent,
              componentId: id, 
            }));
          } else if (update?.request && method === 'action') {
            dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }
      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, e, internalFns);
          }
        });
    }
  }
  };

  const handleHover = (e: React.MouseEvent<HTMLTextAreaElement>) => {
    if(activeState === 'disabled' || activeState === 'loading') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (hoverUpdates) {
      const styleUpdates = hoverUpdates.filter((update): update is StyleUpdateChunk => update && 'style' in update);
      const requestUpdates = hoverUpdates.filter((update): update is RequestUpdateChunk => update && 'request' in update);
      const functionUpdates = hoverUpdates.filter((update): update is FunctionUpdateChunk => update && 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'action') {
            dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }

      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, e, internalFns);
          }
        });
      }
    }
  
  }

  const handleUnhover = (e:  React.MouseEvent<HTMLTextAreaElement>) => {
    if(activeState === 'disabled' || activeState === 'loading') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (unhoverUpdates) {
      const styleUpdates = unhoverUpdates.filter((update): update is StyleUpdateChunk => update && 'style' in update);
      const requestUpdates = unhoverUpdates.filter((update): update is RequestUpdateChunk => update && 'request' in update);
      const functionUpdates = unhoverUpdates.filter((update): update is FunctionUpdateChunk => update && 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'action') {
            dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }
      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, e, internalFns);
          }
        });
      }
    }
  }

  useEffect(() => {
    if (launchUpdates && launchUpdates.length) {
      const requestUpdates = launchUpdates.filter((update): update is RequestUpdateChunk => update && 'request' in update);
      const functionUpdates = launchUpdates.filter((update): update is FunctionUpdateChunk => update && 'function' in update);
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'action') {
            const newTimeout = setTimeout(() => {
              dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
            }, config?.delay * 1000 || 0);

            timeoutsRef.current.push(newTimeout);
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }
      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, undefined, internalFns);
          }
        });
      }
    }

    return () => {
      timeoutsRef?.current?.forEach((timeout) => {
        clearTimeout(timeout);
      });
    };
  }, [launchUpdates]);

  useEffect(() => {
    const handleUnclick = (e: MouseEvent) => {
      if (activeState === 'disabled' || activeState === 'loading') return;
  
      if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
        if (outclickUpdates) {
          const styleUpdates = outclickUpdates.filter((update): update is StyleUpdateChunk => update && 'style' in update);
          const requestUpdates = outclickUpdates.filter((update): update is RequestUpdateChunk => update && 'request' in update);
          const functionUpdates = outclickUpdates.filter((update): update is FunctionUpdateChunk => update && 'function' in update);
  
          if (styleUpdates.length) {
            dispatch(mergeStyleRequest(styleUpdates, parentIndex));
          }
  
          if (requestUpdates.length) {
            requestUpdates.forEach((update) => {
              const { method, value, silent, config } = update?.request || {};
              const { delay } = config || {};
              if (update?.request && method === 'action') {
                dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
              } else if (update?.request && method === 'open-url') {
                handleOpenUrl(value, delay, silent);
              }
            });
          }
  
          if (functionUpdates.length) {
            functionUpdates.forEach((update) => {
              if (update?.function) {
                executeFunction(update.function, undefined, internalFns);
              }
            });
          }
        }
      }
    };
  
    if (outclickUpdates) {
      document.addEventListener('mousedown', handleUnclick);
      return () => {
        document.removeEventListener('mousedown', handleUnclick);
      };
    }
    return undefined;
  }, [parentIndex, activeState, inputRef.current]);

  if (renderSceleton && style) {
    return <ContainerSkeleton 
      style={style}
      baseScreenResolution={baseScreenResolution}
      skipStates={activeState === 'disabled' || activeState === 'loading'}
    />;
  }

  return (
    <StyledTextarea
      ref={inputRef}
      placeholder={placeholder}
      id={`${id}${(nestedTable && typeof parentIndex === 'number') ? `::${parentIndex}` : ''}`}
      $shape={style}
      $baseScreenResolution={baseScreenResolution}
      $skipStates={activeState === 'disabled' || activeState === 'loading'}
      data-component="input"
      className={combineClassNames(style.class)}
      value={internalValue}
      title={tooltip}

      autoFocus={autofocus}
      disabled={activeState === 'disabled'}
      onInput={handleInput}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onMouseEnter={handleHover}
      onMouseLeave={handleUnhover}
      maxLength={regexp?.max_length}
    />
  );
};

const Email: React.FC<EmailInput> = ({ id, style, placeholder }) => <StyledInput placeholder={placeholder} id={id} type="email" $shape={style} data-component="input" className={combineClassNames(style.class)} />;

const Number: React.FC<NumberInput> = ({ id, style, placeholder }) => <StyledInput placeholder={placeholder} id={id} type="number" $shape={style} data-component="input" className={combineClassNames(style.class)} />;

const File: React.FC<FileInput> = ({ id, style, placeholder }) => <StyledInput placeholder={placeholder} id={id} type="file" $shape={style} data-component="input" className={combineClassNames(style.class)} />;

const Phone: React.FC<PhoneInput> = ({ id, style, placeholder }) => <StyledInput placeholder={placeholder} id={id} type="tel" $shape={style} data-component="input" className={combineClassNames(style.class)} />;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Global: React.FC<TextInput | TextAreaInput | PasswordInput | EmailInput | NumberInput | FileInput | PhoneInput | DateTimePickerInput> = (props) => {
  const { type } = props;

  switch (type) {
    case 'text':
    case 'password':
    case 'number':
      return <Input {...props} />;
    case 'textarea':
      return <TextArea {...props} />;
    // case 'password':
    //   return <Password {...props} />;
    case 'email':
      return <Email {...props} />;
    case 'number':
      return <Number {...props} />;
    case 'file':
      return <File {...props} />;
    case 'phone':
      return <Phone {...props} />;
    case 'daterangepicker':
      return <DateRangePicker {...props} />;
    default:
      return <Input {...props as TextInput} />;
  }
};

export default Global;
