import classnames from 'classnames';
import UAParser from 'ua-parser-js';
import moment from 'moment';

import { BaseStyle, NodeTypeScheme } from '../types/base';

export type ScreenResolutions = '4k' | '2k' | 'laptopL' | 'laptop' | 'tablet' | 'mobileL' | 'mobileM' | 'mobileS';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseIfJSON(input: string): any | string {
  try {
    const parsedJSON = JSON.parse(input);
    return parsedJSON;
  } catch (error) {
    // console.log(error);
    // Parsing failed, return the original input
    return input;
  }
}

export function stringifyIfJSON(input: any, pretty: boolean): string | any {
  if (typeof input === 'object' && input !== null) {
    try {
      // Add the second and third parameters to JSON.stringify for indentation
      const jsonString = JSON.stringify(input, null, pretty ? 2 : 0);
      return jsonString;
    } catch (error) {
      // Stringification failed, return the original input
      return '';
    }
  } else {
    // Input is not an object, return it as is
    return '';
  }
}

type ItemType = {
  id: string;
  activeState?: string;
  name?: string;
  component: string;
  value?: string | boolean;
  items?: ItemType[];
  style?: {
    states?: {
      loading?: any;
    };
  };
}

interface RadioUncheckResult {
  items: ItemType[];
  changedIds: string[];
}

type StyleWithoutDisplay = Omit<BaseStyle, 'display'>;

type InternalFunctions = {
  $setVariable: (key: string, value: any) => void;
  $getVariable: (key: string) => any;
  $moment: (...args: any[]) => moment.Moment;
}

export const executeFunction = (
  functionString: string, 
  $event?: React.MouseEvent<HTMLDivElement | HTMLTextAreaElement | HTMLInputElement> | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  internalFns?: InternalFunctions
) => {
  try {
    // Check for state function calls
    const hasSetState = functionString.includes('$setVariable');
    const hasGetState = functionString.includes('$getVariable');
    
    // Create context with allowed internal functions
    const context = {
      $event,
      ...(hasSetState && { $setVariable: internalFns?.$setVariable }),
      ...(hasGetState && { $getVariable: internalFns?.$getVariable })
    };

    // Create function with controlled scope
    const func = new Function(...Object.keys(context), `
      "use strict";
      ${functionString}
    `);

    // Execute with provided context
    func(...Object.values(context));
  } catch (error) {
    console.error('Error executing function:', error);
  }
};

// Helper function to get nested object values using string path
export const getNestedValue = (obj: any, path: string) => {
  // Handle empty/invalid paths
  if (!path) return undefined;

  // Split path into parts (e.g. "user.address.street" -> ["user", "address", "street"])
  const parts = path.split('.');
  let result = obj;

  // Traverse the object following the path
  for (const part of parts) {
    if (result === null || result === undefined) {
      return undefined;
    }
    result = result[part];
  }

  return result;
};


export const debounce = (func: Function, delay: number) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function deb (...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
};

// Helper function to check if nodeId matches the pattern
export const matchesPattern = (pattern: string, nodeId: string): boolean => {
  const regex = new RegExp(`^${pattern.replace('*', '.*')}$`);
  return regex.test(nodeId);
};

export function formCacheKey(obj: { command?: string, action?: string, component_id?: string, screen_id?: string }): string {
  const { command, action, component_id, screen_id } = obj || {};
  let cacheKey = command;

  if (component_id && screen_id) {
    if (action) {
      cacheKey = `${screen_id}/${component_id}/${action}`;
    } else if (command) {
      cacheKey = `${screen_id}/${component_id}/${command}`;
    }
  }

  return cacheKey;
}

export function transformShimmerCache(scheme: object[]): {
  status: boolean;
  transformed: object[];
} {
  let hasCache = false;
  const pattern = /{{.*}}/;

  function analyzeObject(obj: any): any {
    // Create copy of object to avoid mutations
    const result = { ...obj };

    // Check if current object has cache:true or value matches the pattern
    if (
      obj.cache === true ||
      (typeof obj.value === 'string' && pattern.test(obj.value) && obj.cache !== false)
    ) {
      hasCache = true;
      result.sceleton = true;
      return result;
    }

    // Only process if items array exists
    if (Array.isArray(obj.items)) {
      result.items = obj.items.map((item) => analyzeObject(item));
    }

    return result;
  }

  const transformed = scheme.map((obj) => analyzeObject(obj));

  return {
    status: hasCache,
    transformed,
  };
}

export function recursiveUpdateById(
  items: ItemType[],
  idsObject: Record<string, any>,
  updateFunc: (item: ItemType, insideTable: boolean) => ItemType,
  insideTable: boolean = false
): ItemType[] {
  return items.map((item) => {
    const isTable = item.component === "table";
    const newInsideTable = insideTable || isTable;

    // Check if item.id matches any pattern in ids or if item.id is directly in ids
    const idMatches = Object.keys(idsObject).some((id) => id === item.id || (matchesPattern(id, item.id) && idsObject[id]?.object_type === item.component));
    if (idMatches) {
      item = updateFunc({ ...item }, newInsideTable);
    }
    if (item.items && item.items.length > 0) {
      return { ...item, items: recursiveUpdateById(item.items, idsObject, updateFunc, newInsideTable) };
    }
    return item;
  });
}

export function recursivelyUpdateActiveState(items: ItemType[], excludeId: string): ItemType[] {
  return items.map((item) => {
    if (!(item.id === excludeId && item.component === 'input') && item.style && item.style.states && item.style.states.loading) {
      item.activeState = 'loading';
    }
    if (item.items && item.items.length > 0) {
      return { ...item, items: recursivelyUpdateActiveState(item.items, excludeId) };
    }
    return item;
  });
}

export function reccursivelyUncheckRadioItems(
  items: ItemType[], 
  name: string, 
  excludeId: string
): RadioUncheckResult {
  const changedIds: string[] = [];

  const updatedItems = items.map((item) => {
    if (item.component === 'radio' && item.name === name && item.id !== excludeId) {
      item.value = false;
      changedIds.push(item.id);
    } else if (item.component === 'radio' && item.name === name && item.id === excludeId) {
      item.value = true;
    }

    if (item.items && item.items.length > 0) {
      const nestedResult = reccursivelyUncheckRadioItems(item.items, name, excludeId);
      changedIds.push(...nestedResult.changedIds);
      return { ...item, items: nestedResult.items };
    }

    return item;
  });

  return {
    items: updatedItems,
    changedIds
  };
}


export function attachStyle(style: string) {
  const tag = document.createElement('style');
  tag.innerHTML = style;
  document.head.appendChild(tag);
}

export function generateCssRule(className: string, style: object): string {
  const cssProperties = Object.keys(style)
    .map((property) => {
      let value = style[property];

      if (typeof value === 'string' && value.startsWith('$')) {
        value = `var(--${value.slice(1)})`;
      }

      return `${property.replace(/([A-Z])/g, '-$1').toLowerCase()}: ${value};`;
    })
    .join('\n');

  return `.${className} {\n${cssProperties}\n}`;
}

export const filterDisplayProperty = (styles: BaseStyle): StyleWithoutDisplay => {
  const { display, ...filteredStyles } = styles || {};
  return filteredStyles;
};

export function isUndefined(value?: string | null | { [key: string]: string }) {
  return typeof value === 'undefined' || value === null || value.length === 0;
}

export function random() {
  return Math.floor(Math.random() * 0xffff);
}

export function replaceKeyWithCssVar(inputString: string) {
  if (!inputString || !inputString.length) return inputString;

  return inputString.replace(/\$[a-zA-Z0-9_-]+/g, match => `var(--${match.slice(1)})`);
}


export const combineClassNames = (...classes) => {
  const processedClasses = classes.reduce((acc, className) => {
    if (typeof className === 'string') {
      acc.push(...className.split(' '));
    } else if (Array.isArray(className)) {
      acc.push(...className);
    } else if (className && typeof className === 'object') {
      Object.keys(className).forEach((key) => {
        if (className[key]) {
          acc.push(key);
        }
      });
    }
    return acc;
  }, []);

  return classnames(...processedClasses);
};

export const resolveSpacing = (spacing: Array<number | string>): string => {
  if (Array.isArray(spacing)) {
    const [top = 0, right = 0, bottom = 0, left = 0] = spacing.map((val) => (typeof val === 'number' ? `${val}px` : val));

    return `${top} ${right} ${bottom} ${left}`;
  } if (typeof spacing === 'number') {
    return `${spacing}px`;
  }

  return spacing;
};

export function isValidSvg(svgString: string) {
  if (typeof svgString !== 'string') {
    return false;
  }

  if (!svgString.trim().match(/^<svg[^>]*>[\s\S]*<\/svg>$/i)) {
    return false;
  }
  const parser = new DOMParser();
  const doc = parser.parseFromString(svgString, 'image/svg+xml');

  return doc.documentElement.nodeName === 'svg';
}

export function applyGradientOpacity(gradient: string, opacity: number): string {
  // Regular expression to match the gradient type (linear or radial)
  const gradientRegex = /^(linear|radial)-gradient\((.*)\)/;

  // Extract the gradient type and content
  const match = gradientRegex.exec(gradient);
  if (!match) {
    throw new Error("Invalid gradient format");
  }

  const gradientType = match[1];
  const gradientContent = match[2];

  // Apply opacity to each color stop
  const colorStops = gradientContent.split(",");
  const updatedColorStops = colorStops.map((colorStop) => {
    // Extract the color and opacity (if present)
    const colorMatch = /rgba?\((.*)\)/.exec(colorStop);
    if (colorMatch) {
      const colorValues = colorMatch[1].split(",");
      const alpha = parseFloat(colorValues[3] || "1");
      const updatedAlpha = alpha * opacity;
      return `rgba(${colorValues[0]}, ${colorValues[1]}, ${colorValues[2]}, ${updatedAlpha})`;
    } else {
      // If no opacity is present, apply the desired opacity directly
      const updatedAlpha = opacity;
      return `rgba(${colorStop}, ${updatedAlpha})`;
    }
  });

  // Reconstruct the gradient with the updated color stops
  const updatedGradient = `${gradientType}-gradient(${updatedColorStops.join(",")})`;

  return updatedGradient;
}

export function getDeviceInfo() {
  const parser = new UAParser();
  const browserInfo = parser.getResult();

  let geodata = null;
  if (navigator.permissions) {
    // const permission = await navigator.permissions.query({ name: 'geolocation' });
    // if (permission.state === 'granted') {
    //   geodata = await new Promise(resolve => navigator.geolocation.getCurrentPosition(position => resolve(position.coords)));
    // }
  }

  return {
    localization: navigator.language || null,
    device_id: null,
    device_os: browserInfo.os.name || null,
    browser_version: browserInfo.browser.version || null,
    browser_provider: browserInfo.browser.name || null,
    front_version: "0.1",
    back_version: "0.1",
    screen_resolution: {
      w: window.screen.width || null,
      h: window.screen.height || null
    },
    device_type: browserInfo.device.type || 'desktop',
    external_ip: null,
    internal_ip: null,
    geodata: geodata ? {
      latitude: geodata.latitude,
      longitude: geodata.longitude,
      altitude: geodata.altitude,
      accuracy: geodata.accuracy,
      altitude_accuracy: geodata.altitudeAccuracy,
      heading: geodata.heading,
      speed: geodata.speed
    } : null
  };
}

// Helper function to parse form values
export function parseFormValues(section: any) {
  const formValues: Record<string, any> = {};
  const parseItems = (items: any[]) => {
    items.forEach(item => {
      if (item.id && item.value !== undefined && 
        (item.component === 'input' || item.component === 'checkbox' || item.component === 'radio' || item.component === 'select' || item.component === 'switcher')
      ) {
        formValues[item.id] = item.value;
      }
      if (item.items && item.items.length > 0) {
        parseItems(item.items);
      }
    });
  };

  if (Array.isArray(section)) {
    section.forEach((sec) => {
      if (sec.items) {
        parseItems(sec.items);
      }
    });
  }

  if (section && section.items) {
    parseItems(section.items);
  }

  return formValues;
}

export const isInCurlyBracesFormat = (value: string) => {
  const regex = /^\{\{.*\}\}$/;
  return regex.test(value);
};

export const extractValueInsideCurlyBraces = (value: any): string => {
  if (typeof value !== 'string') {
    return stringifyIfJSON(value, false);
  }
  const match = value.match(/^\{\{(.*)\}\}$/);
  return match ? match[1] : value;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getStringValueFromPath = (obj: Record<string, any>, path: string): string => {
  // Handle the case where the path is exactly 'this'
  if (path === 'this') {
    return typeof obj === 'string' ? obj : JSON.stringify(obj);
  }

  // Remove 'this.' prefix if it exists
  const normalizedPath = path.startsWith('this.') ? path.slice(5) : path;
  const value = normalizedPath.split('.').reduce((acc, part) => acc && acc[part], obj);
  return typeof value === 'string' ? value : JSON.stringify(value);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getNestedValue2 = (obj: any, path: string): any => path.split('.').reduce((acc, part) => {
  const value = (acc !== undefined && acc !== null && typeof acc === 'object' && !Array.isArray(acc)) ? acc[part] : acc;
  if (typeof value === 'number' || typeof value === 'boolean') {
    return value.toString();
  }
  return value;
}, obj);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const replacePlaceholdersWithVariables = (style: any, variables: any): any => {
  const parsedVars = parseIfJSON(variables);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const replacePlaceholders = (obj: any): any => {
    if (typeof obj === 'string') {
      const regex = /{{(.*?)}}/g;
      let match;
      let result = obj;
      const matches = [];
      // eslint-disable-next-line no-cond-assign
      while ((match = regex.exec(obj)) !== null) {
        matches.push(match);
      }
      // eslint-disable-next-line @typescript-eslint/no-shadow
      matches.forEach((match) => {
        if (match[0].startsWith('{{this')) {
          return;
        }
  
        const variablePath = match[1].trim();
        const variableValue = getNestedValue2(parsedVars, variablePath);
        // eslint-disable-next-line no-nested-ternary
        const replacement = variableValue !== undefined ? (typeof variableValue === 'object' ? JSON.stringify(variableValue) : variableValue) : '';
        result = result.replace(match[0], replacement);
      });
      return result;
    } if (Array.isArray(obj)) {
      return obj.map((item) => replacePlaceholders(item));
    } if (typeof obj === 'object' && obj !== null) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newObj: { [key: string]: any } = {};
      Object.keys(obj).forEach((key) => {
        newObj[key] = replacePlaceholders(obj[key]);
      });
      return newObj;
    }
    return obj;
  };

  return replacePlaceholders(style);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const replacePlaceholdersWithVariablesV2 = (style: any, variables: any): any => {
  const parsedVars = parseIfJSON(variables);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const replacePlaceholders = (obj: any): any => {
    if (typeof obj === 'string') {
      const regex = /{{(this(?:\..*?)?)}}/g;
      let match;
      let result = obj;
      const matches = [];
      // eslint-disable-next-line no-cond-assign
      while ((match = regex.exec(obj)) !== null) {
        matches.push(match);
      }
      // eslint-disable-next-line @typescript-eslint/no-shadow
      matches.forEach((match) => {
        const variablePath = match[1].trim();
        let replacement;
        if (variablePath === 'this') {
          replacement = JSON.stringify(parsedVars);
        } else {
          const variableValue = getNestedValue2(parsedVars, variablePath.slice(5)); // Remove 'this.' from the path
          replacement = variableValue !== undefined ? (typeof variableValue === 'object' ? JSON.stringify(variableValue) : variableValue) : '';
        }
        result = result.replace(match[0], replacement);
      });
      return result;
    } if (Array.isArray(obj)) {
      return obj.map((item) => replacePlaceholders(item));
    } if (typeof obj === 'object' && obj !== null) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newObj: { [key: string]: any } = {};
      Object.keys(obj).forEach((key) => {
        newObj[key] = replacePlaceholders(obj[key]);
      });
      return newObj;
    }
    return obj;
  };

  return replacePlaceholders(style);
};

export function attachScriptsToHeader(scriptUrls: string) {
  // Regex to match <script> tags
  const scriptTagRegex = /<script.*?>([\s\S]*?)<\/script>/gs;
  // Regex to match URLs not inside <script> tags
  const urlRegex = /https?:\/\/[^\s;]+(?![^<]*<\/script>)/g;

  const scriptTagsArray = scriptUrls.match(scriptTagRegex) || [];
  const urlsArray = scriptUrls.match(urlRegex) || [];

  scriptTagsArray.forEach((scriptContent) => {
    attachScriptIfMissing(scriptContent, true);
  });

  urlsArray.forEach((url) => {
    attachScriptIfMissing(url, false);
  });
}

export function attachFontsToHeader(fonts: string) {
  const fontRegex = /<link.*?href="([^"]+)"[^>]*>/gs;
  const fontUrls = fonts.match(fontRegex) || [];

  fontUrls.forEach((fontUrl) => {
    attachFontIfMissing(fontUrl);
  });
}

function attachFontIfMissing(fontString: string) {
  const tempContainer = document.createElement('div');
  tempContainer.innerHTML = fontString;
  const fontElement = tempContainer.firstChild as HTMLLinkElement;

  if (!fontElement || fontElement.tagName !== 'LINK' || fontElement.rel !== 'stylesheet') {
    console.error('Invalid font string');
    return;
  }

  const existingFonts = document.querySelectorAll('link[rel="stylesheet"]');

  // Check if the font is already present
  for (let i = 0; i < existingFonts.length; i++) {
    if ((existingFonts[i] as HTMLLinkElement).href === fontElement.href) {
      return; // Font already exists
    }
  }

  // Attach the font
  document.head?.appendChild(fontElement);
}

function attachScriptIfMissing(scriptContent: string, isInline: boolean) {
  const existingScripts = document.querySelectorAll('script');

  if (!isInline) {
    // Check if the URL script is already present
    for (let i = 0; i < existingScripts.length; i++) {
      if (existingScripts[i].src === scriptContent) {
        return; // Script already exists
      }
    }

    // Attach the URL script
    const script = document.createElement('script');
    script.src = scriptContent;
    document.head?.appendChild(script);
  } else {
    // Extract the inner content of the inline script
    const inlineScriptContent = extractScriptContent(scriptContent);

    // Check if the inline script is already present
    for (let i = 0; i < existingScripts.length; i++) {
      if (existingScripts[i].innerHTML.trim() === inlineScriptContent) {
        return; // Script already exists
      }
    }

    // Attach the inline script
    const script = document.createElement('script');
    script.innerHTML = inlineScriptContent;
    document.head?.appendChild(script);
  }
}

function extractScriptContent(content: string): string {
  const scriptRegex = /<script.*?>([\s\S]*?)<\/script>/s;
  const match = scriptRegex.exec(content);

  return match ? match[1].trim() : '';
}


// urlHelper.ts
export const handleOpenUrl = (value: string, delay: number, isOpenNewTab: boolean) => {
  const openUrl = () => {
    const url = value.startsWith('/') ? `${window.location.origin}${value}` : value;
    const isDownloadable = url.match(/\.(pdf|docx?|xlsx?|zip|rar|tar\.gz|jpg|jpeg|png|gif)$/i);

    if (isDownloadable) {
      const link = document.createElement('a');
      link.href = url;
      link.rel = 'noopener noreferrer';
      link.target = '_blank';
      link.download = '';
      link.click();
    } else if (isOpenNewTab) {
      window.open(url, '_blank', 'noopener,noreferrer');
    } else {
      window.location.href = url;
    }
  };

  if (delay && delay > 0) {
    setTimeout(openUrl, delay * 1000);
  } else {
    openUrl();
  }
};

export const isStringVariable = (source?: string): boolean => /^\{\{(.*)\}\}$/.test(source || '');

// eslint-disable-next-line @typescript-eslint/no-explicit-any, max-len
export function getCorrespondingScheme(data: NodeTypeScheme, parentData: NodeTypeScheme, dataLake: any, i: number, variables: any, parentI = 0): any {
  if (data?.type === 'table-header-row' || data?.type === 'table-body-row') {
    // eslint-disable-next-line max-len
    const replacedVariables = isStringVariable(parentData?.settings?.source) ? replacePlaceholdersWithVariables(parentData?.settings?.source, variables) : parentData?.settings?.source;
    const parseSourceIfExist = parseIfJSON(replacedVariables);
    return {
      settings: { ...parentData?.settings, source: replacedVariables },
      cols: parentData?.cols,
      data: Array.isArray(parseSourceIfExist) ? parseSourceIfExist[i] : undefined,
    };
  }
  if (data?.type === 'table-header-row-cell') {
    // eslint-disable-next-line max-len
    const replacedVariables = isStringVariable(dataLake?.settings?.source) ? replacePlaceholdersWithVariables(dataLake?.settings?.source, variables) : dataLake?.settings?.source;
    return {
      settings: { ...dataLake?.settings, source: replacedVariables },
      cols: dataLake?.cols,
      data: dataLake?.cols?.items?.[i],
    };
  }
  if (data?.type === 'table-body-row-cell') {
    const parseSourceIfExist = parseIfJSON(dataLake?.settings?.source);
    return {
      settings: dataLake?.settings,
      cols: dataLake?.cols,
      data: Array.isArray(parseSourceIfExist) ? parseSourceIfExist[parentI] : undefined,
    };
  }
  if (dataLake) {
    return dataLake;
  }
  return undefined;
}

export const getSizesBasedOnScreen = (screen: ScreenResolutions): number => {
  switch (screen) {
    case '4k':
      return 2560;
    case '2k':
      return 1920;
    case 'laptopL':
      return 1440;
    case 'laptop':
      return 1024;
    case 'tablet':
      return 768;
    case 'mobileL':
      return 425;
    case 'mobileM':
      return 375;
    case 'mobileS':
      return 320;
    default:
      return 1440;
  }
};

