.page {
  width: 100%;
  min-height: 100vh;
  position: relative;
  flex-direction: column;
}

.pageWrap {
  display: flex;
  height: 100vh;
  width: 100%;
  position: relative;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
}
