import React, { FC } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import generateComponentStyles from '../../../utils/styleHelper';

import styles from './ContainerSkeleton.module.scss';

type SkeletonBaseProps = {
  style: any;
  skipStates?: boolean;
  baseScreenResolution?: number;
  nested?: boolean;
}

const StyledContainer = styled.div`
  ${(props) => generateComponentStyles(props.$shape, props.$baseScreenResolution, props.$skipStates)}
    position: relative;
    visibility: visible;
    pointer-events: none;
  ${(props) => props.$nested && `
    position: absolute;
    z-index: 99999999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  `}
`;

const ContainerSkeleton: FC<SkeletonBaseProps> = ({ 
  style,
  skipStates,
  baseScreenResolution,
  nested
}) => (
  <StyledContainer 
    $shape={{ 
      display: style.display, grid: style.grid,
      flex: style.flex, width: style.width, height: style.height,
      min_height: !style.height ? '16px' : undefined,
      border: { radius: style.border.radius, },
      }} 
      $baseScreenResolution={baseScreenResolution} 
      $skipStates={skipStates} 
      $nested={nested}
      className={cn('shimmer-container', styles.container)}
  >
    </StyledContainer>
);

export default ContainerSkeleton;