import React, { useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Container from '../../components/Container';
import Checkbox from '../../components/Checkbox';
import Image from '../../components/Image';
import Input from '../../components/Input';
import Radio from '../../components/Radio';
import Select from '../../components/Select';
import Separator from '../../components/Separator';
import Switcher from '../../components/Switcher';
import Text from '../../components/Text';
import { pageSuccess } from '../../store/page/actions';
import { 
  setItem as setLocalCache,
 } from '../../utils/indexedDbHelper';
import merge from 'lodash.merge';
import { analyzeAndPrefetchImages } from '../../utils/prefetchHelper';
import { preloadImages } from '../../utils/preloadHelper';
import generateComponentStyles from '../../utils/styleHelper';
import { generateCssRule, filterDisplayProperty, 
  attachScriptsToHeader, attachFontsToHeader, replacePlaceholdersWithVariables, 
  formCacheKey, transformShimmerCache } from '../../utils/base';
import { ApplicationState } from '../../store';
import { io, Socket } from 'socket.io-client';

export interface WebSocketMessage {
  type: string;
  data?: any;
  messageId?: string;
  requireAck?: boolean;
  requestData?: {
    command?: string;
    action?: string;
    component_id?: string;
    screen_id?: string;
  }
}

const parseOldFormat = (data: any) => {
  const { sections } = data || {};
  const [first] = sections || [];
  return { page: first, settings: {}, variableData: {}, screenId: undefined };
}

const parseNewFormat = (data: any) => {
  const { screen, variable_data: variableData } = data || {};
  const { sections, settings, id } = screen || {};
  return { page: sections, settings, variableData, screenId: id };
}

import { rowSizeGlobal, colCountGlobal } from '../../config/config';

import * as styles from './page.module.scss';
import { NodeTypeScheme } from '../../types/base';

const styleElementId = 'custom-css-header-kdlshHhgjhgf';

const getWebSocketUrl = () => {
  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
  return `${protocol}//${window.location.host}/ws`;
};

const componentMapping = {
  checkbox: Checkbox,
  image: Image,
  input: Input,
  radio: Radio,
  select: Select,
  separator: Separator,
  switcher: Switcher,
  text: Text,
};

const Page: React.FC = () => {
  const dispatch = useDispatch();
  const socketRef = useRef<Socket | null>(null);
  const config = useSelector((state: ApplicationState) => state.config);
  const page = useSelector((state: ApplicationState) => state.page);
  const { data } = config || {};
  const { data: pageData2, settings, variableData, sessionData, styleChunks } = page || {};
  const { deviceId, sessionId } = sessionData || {};
  const mergedVariables = merge({}, (settings?.variables || {}), variableData || {});
  const pageData = replacePlaceholdersWithVariables(pageData2, mergedVariables);
  const newSettings = { ...settings, variables: mergedVariables };
  const { page: pp } = pageData || {};

  useEffect(() => {
    const { basic, variables, classes } = data || {};
  
    if (basic && variables && classes) {
      const classKeys = Object.keys(classes);
      let styleElement = document.getElementById(styleElementId);
      if (!styleElement) {
        styleElement = document.createElement('style');
        styleElement.id = styleElementId;
        document.head.appendChild(styleElement);
      }
      const resulting = {};
      classKeys.forEach((k) => {
        const classStyles = filterDisplayProperty(classes[k]);
        resulting[k] = generateComponentStyles(classStyles);
      });
      const cssRules = Object.keys(resulting)
        .map((className) => generateCssRule(className, resulting[className]))
        .join('\n\n');
      styleElement.innerText = `${variables}\n${basic}\n${cssRules}`;
    }
  }, [data]);

  useEffect(() => {
    if (settings?.script) attachScriptsToHeader(settings?.script);
  }, [settings?.script]);

  useEffect(() => {
    if (settings?.fonts) attachFontsToHeader(settings?.fonts);
  }, [settings?.fonts]);

  useEffect(() => {
    if (settings?.page) {
      const { title, icon } = settings.page;
      if (title) {
        document.title = title;
      }
      if (icon) {
        let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = icon;
      }
    }
  }, [settings]);

  const connectWebSocket = useCallback(() => {
    if (!deviceId || !sessionId || socketRef.current) return;

    const socket = io(getWebSocketUrl(), {
      path: "/ws",
      transports: ['websocket'],
    });

    socket.on('connect', () => {
      console.log('WebSocket Connected');
      socket.emit('authorize', { 
        groupIdentifier: deviceId,
        sessionIdentifier: sessionId,
      });
    });

    socket.on('disconnect', (reason) => {
      console.log('WebSocket Disconnected', reason);
    });

    socket.on('connect_error', (error) => {
      console.error('WebSocket Error:', error);
    });

    socket.on('pageUpdate', (message: WebSocketMessage) => {
      if (message.requireAck && message.messageId) {
        socket.emit('ack', { messageId: message.messageId });
      }
      if (message.type === 'pageUpdate' && message.data) {
        const { data } = message.data || {};
        const { page: pp, settings, variableData, screenId } = data.sections ? parseOldFormat(data) : parseNewFormat(data);
        dispatch(pageSuccess({ 
          data: pp, 
          settings, 
          sessionData, 
          variableData,
          screenId,
         }));
         if (message.data?.requestData && message.data?.requestData.component_id &&  message.data?.requestData.screen_id) {
          const { command, action, component_id: componentId, screen_id: screenId } = message.data?.requestData;
           const cacheKey = formCacheKey({ command, action, component_id: componentId, screen_id: screenId });
           const { status, transformed } = transformShimmerCache(data?.screen?.sections);

           if (status && transformed) {
            setLocalCache(cacheKey, {
              ...data,
              screen: {
                ...data.screen,
                sections: transformed,
              },
              variable_data: variableData,
            });
           }
         }
      }
    });

    socketRef.current = socket;

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [deviceId, sessionId, dispatch]);

  useEffect(() => {
    if (deviceId && sessionId) {
      connectWebSocket();
    }
  }, [deviceId, sessionId, connectWebSocket]);

  useEffect(() => {
    if (!pp) return;
    const url = analyzeAndPrefetchImages(pp);
    preloadImages(url);
  }, [pp]);

  return (
    <div className={styles.page}>
      <div className={styles.pageWrap}
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${settings?.cols || colCountGlobal}, 1fr)`,
        gridAutoRows: settings?.rows ? undefined : `${rowSizeGlobal}px`,
        gridTemplateRows: settings?.rows ? `repeat(${settings?.rows}, 1fr)` : undefined,
      }}
      >
        {(typeof pp === 'object' && Object.keys(pp) && !Array.isArray(pp)) ? (
          <Container 
            {...pp as NodeTypeScheme} 
            pageSettings={newSettings}
            styleChunks={styleChunks}
          />
        ) : null}

        {Array.isArray(pp) ? (
          <>
            {pp.map((item) => {
              const Component = componentMapping[item.component];
              if (Component) {
                return <Component key={item.id} {...item} pageSettings={newSettings} styleChunks={styleChunks} />;
              } else if (item.component === 'container' || item.component === 'table') {
                return <Container key={item.id} {...(item as NodeTypeScheme)} pageSettings={newSettings} styleChunks={styleChunks} />;
              }
              return null;
            })}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Page;