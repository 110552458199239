/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
import {
  FullStyle as Type,
  Cursor,
  Position,
  TextAlign,
  VerticalAlign,
  BackgroundStyle,
  BorderStyle,
  FlexStyle,
} from '../types/base';

import {
  isValidSvg,
  getSizesBasedOnScreen,
  ScreenResolutions,
} from '../utils/base';

import {
  replaceKeyWithCssVar,
  resolveSpacing,
} from './base';

const blockList = ['loading', 'success', 'error', 'disabled'];

function getOverflow(scroll, font, display, flex) {
  if (font?.text_overflow === 'ellipsis' && !scroll?.type) {
    return {
      overflow: 'hidden',
    };
  } else if (!scroll?.type) {
    return {};
  }

  const overflow = scroll?.direction === 'both' ? (scroll?.type === 'none' ? 'auto' : (scroll?.type || 'auto')) : font?.text_overflow === 'ellipsis' ? 'hidden' : undefined;
  const overflowX = (scroll?.direction === 'x' || scroll?.direction === 'both') ? (scroll?.type || 'auto') : (display === 'grid')
    ? 'hidden'
    : (flex?.direction === 'row')
      ? (scroll?.type === 'none' ? 'auto' : (scroll?.type || 'hidden'))
      : 'hidden';
  const overflowY = (scroll?.direction === 'y' || scroll?.direction === 'both') ? (scroll?.type || 'auto') : (display === 'grid')
    ? (scroll?.type === 'none' ? 'auto' : (scroll?.type || 'hidden'))
    : (flex?.direction === 'column')
      ? (scroll?.type === 'none' ? 'auto' : (scroll?.type || 'hidden'))
      : 'hidden';

  return {
    overflow,
    overflowX,
    overflowY,
  };
}

function getBackgroundImage(input: string): string {
  if (isValidSvg(input)) {
    // If the input is a valid SVG, use it as a data URL
    return `url("data:image/svg+xml,${encodeURIComponent(input)}")`;
  } else {
    // If the input is not a valid SVG, assume it's a URL to an image
    return `url("${input}")`;
  }
}

// Helper type guards
const isBorderDirectional = (width: any): width is { type?: 'top' | 'right' | 'bottom' | 'left', value: string | number } => {
  return width?.type && ['top', 'right', 'bottom', 'left'].includes(width.type);
};

const isBorderCustom = (width: any): width is { type: 'custom', value: (string | number)[] } => {
  return width?.type === 'custom' && Array.isArray(width.value);
};
const generateGapStyles = (flex: FlexStyle | null | undefined): Record<string, string | number> => {
  if (!flex?.gap) return {};
  
  // Handle single number or string value
  if (typeof flex.gap === 'number') {
    return { gap: `${flex.gap}px` };
  }

  if (typeof flex.gap === 'string') {
    // Split by space or comma and trim whitespace
    const values = flex.gap.split(/[\s,]+/).filter(Boolean).map(val => val.trim());
    
    // Process each value to ensure proper format
    const processedValues = values.map(val => {
      // If it's a pure number
      if (/^\d+$/.test(val)) {
        return `${val}px`;
      }
      // If it already has units (px, em, rem, etc.)
      if (/^\d+(?:px|em|rem|vh|vw|%)$/.test(val)) {
        return val;
      }
      // Invalid format
      return val;
    });

    // Return formatted gap value based on number of values
    if (processedValues.length === 1) {
      return { gap: processedValues[0] };
    }
    if (processedValues.length === 2) {
      return { gap: processedValues.join(' ') };
    }
    // If more than 2 values, only use the first two
    return { gap: `${processedValues[0]} ${processedValues[1]}` };
  }

  return {};
};
// Modified border width generation
const generateBorderWidth = (border: BorderStyle) => {
  if (!border?.width) return {};

  if (typeof border.width === 'string' || typeof border.width === 'number') {
    return { borderWidth: border.width };
  }

  if (border.width === null) {
    return { borderWidth: 0 };
  }

  if (isBorderDirectional(border.width)) {
    const { type, value } = border.width;
    return {
      borderWidth: 0,
      [`border${type?.charAt(0).toUpperCase()}${type?.slice(1)}Width`]: value,
    };
  }

  if (isBorderCustom(border.width)) {
    return {
      borderTopWidth: border.width.value[0],
      borderRightWidth: border.width.value[1],
      borderBottomWidth: border.width.value[2],
      borderLeftWidth: border.width.value[3],
    };
  }

  return {};
};

const generateBorderRadius = (border: BorderStyle) => {
  if (!border?.radius) return {};

  if (typeof border.radius === 'string' || typeof border.radius === 'number') {
    return { borderRadius: border.radius };
  }

  if (border.radius === null) {
    return { borderRadius: 0 };
  }

  if (isBorderCustom(border.radius)) {
    return {
      borderTopLeftRadius: border.radius.value[0],
      borderTopRightRadius: border.radius.value[1],
      borderBottomRightRadius: border.radius.value[2],
      borderBottomLeftRadius: border.radius.value[3],
    };
  }

  return {};
}

const parseBackgroundSize = (url: string): { url: string, backgroundSize: string | null } => {
  try {
    const baseUrl = window.location.origin; // Base URL for relative URLs
    const urlObj = new URL(url, baseUrl);
    const backgroundSize = urlObj.searchParams.get('backgroundSize');
    if (backgroundSize !== null) {
      urlObj.searchParams.delete('backgroundSize');
    }
    const resultUrl = url.startsWith('http') ? urlObj.toString() : urlObj.pathname + urlObj.search;
    return { url: resultUrl, backgroundSize };
  } catch (e) {
    return { url, backgroundSize: null };
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const filterUndefinedProperties = (obj: { [key: string]: any }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filteredObj: { [key: string]: any } = {}; // Add index signature
  const validNumberRegex = /^-?\d+(\.\d+)?$/; // Regular expression to match valid numbers

  for (const key in obj) {
    if (obj[key] !== undefined) {
      let value = obj[key];
      if (typeof value === 'string' && validNumberRegex.test(value)) {
        value = parseFloat(value);
      }
      filteredObj[key] = replaceKeyWithCssVar(value);
    }
  }
  return filteredObj;
};

function getBackgroundProperties(shapes?: BackgroundStyle[] | null) {
  if (!shapes || shapes.length === 0 || !Array.isArray(shapes)) {
    return {
      backgroundImage: undefined,
      backgroundPosition: undefined,
      backgroundSize: undefined,
      backgroundRepeat: undefined,
    };
  }

  const backgroundImages: string[] = [];
  const backgroundPositions: string[] = [];
  const backgroundSizes: string[] = [];
  const backgroundRepeats: string[] = [];

  shapes
    .filter((el) => !((el.type === 'image' || el.type === 'gradient') && el.opacity === 0))
    .forEach((shape) => {
      if (shape.value.startsWith('linear-gradient') || shape.value.startsWith('radial-gradient')) {
        backgroundImages.push(shape.value);
        backgroundPositions.push('center');
        backgroundSizes.push('cover');
        backgroundRepeats.push('no-repeat');
      } else if (shape.value.startsWith('http') || shape.value.startsWith('/')) {
        backgroundImages.push(`url(${parseBackgroundSize(shape.value).url})`);
        backgroundPositions.push('center');
        backgroundSizes.push(parseBackgroundSize(shape.value).backgroundSize || 'contain');
        backgroundRepeats.push('no-repeat');
      } else {
        const gradientValue = `linear-gradient(${shape.value}, ${shape.value})`;
        backgroundImages.push(gradientValue);
        backgroundPositions.push('center');
        backgroundSizes.push('cover');
        backgroundRepeats.push('no-repeat');
      }
    });

  return {
    backgroundImage: backgroundImages.reverse().join(', '),
    backgroundPosition: backgroundPositions.reverse().join(', '),
    backgroundSize: backgroundSizes.reverse().join(', '),
    backgroundRepeat: backgroundRepeats.reverse().join(', '),
  };
}

const generateComponentStyles = (style: Type, baseWidth?: number, skipStates?: boolean, parentDisplay?: string) => {
  if (!style) return null;
  const {
    font,
    align,
    height,
    width,
    max_width,
    min_width,
    max_height,
    min_height,
    margin,
    padding,
    grid,
    border,
    position,
    z_index,
    user_select,
    scroll,
    cursor,
    breakpoints,
    states,
    display,
    flex,
    box,
    background,

    left,
    bottom,
    top,
    right,

    object,

    transform,
    placeholder,
    opacity,
    visibility: visibilityParam,
    // cols,
    // rows,
  } = style;

  const isFlexObject = flex && typeof flex !== 'string';
  const isBorderObject = border && typeof border !== 'string';
  const isPaddingArray = padding && Array.isArray(padding);
  const isMarginArray = margin && Array.isArray(margin);
  const isMarginObject = margin && typeof margin === 'object' && !Array.isArray(margin);
  const isPaddingObject = padding && typeof padding === 'object' && !Array.isArray(padding);
  // const backgroundShape = background?.[background.length - 1];

  const placeholderStyles = filterUndefinedProperties({
    fontWeight: placeholder?.weight,
    fontStyle: placeholder?.style,
    fontSize: placeholder?.size,
    color: placeholder?.color,
    fontFamily: placeholder?.family,
    lineHeight: placeholder?.height,
    letterSpacing: placeholder?.letter_spacing,
    textDecoration: placeholder?.decoration,
    textTransform: placeholder?.transform,
  });

  const componentStyles = filterUndefinedProperties({
    textOverflow: font?.text_overflow,
    textTransform: font?.transform,
    whiteSpace: font?.text_overflow === 'ellipsis' ? 'nowrap' : font?.text_wrap,
    fontSize: font?.size,
    fontWeight: font?.weight,
    lineHeight: font?.height,
    letterSpacing: font?.letter_spacing,
    color: font?.color,
    fontFamily: font?.family,
    fontStyle: font?.style,
    textDecoration: font?.decoration,
    textAlign: align?.horizontal as TextAlign,
    verticalAlign: align?.vertical as VerticalAlign,
    gridAutoFlow: grid?.auto?.flow,
    gridAutoColumns: grid?.auto?.columns,
    gridAutoRows: grid?.auto?.rows,
    height,
    width,
    maxWidth: max_width || ((typeof flex?.basis === 'number' && width) ? width : undefined),
    minWidth: min_width || ((typeof flex?.basis === 'number' && width) ? width : undefined),
    maxHeight: max_height,
    minHeight: min_height,
    margin: isMarginArray ? resolveSpacing(margin) : typeof margin === 'string' ? margin : undefined,
    padding: isPaddingArray ? resolveSpacing(padding) : typeof padding === 'string' ? padding : undefined,
    display: visibilityParam && visibilityParam === 'hidden' ? 'none' : (visibilityParam && visibilityParam === 'visible') ? (display || parentDisplay) : display,
    alignItems: grid?.align_items || (isFlexObject ? flex.align_items : undefined),
    alignContent: (isFlexObject) ? flex.align_items : undefined,
    cursor: cursor as Cursor,
    gridColumnStart: grid?.x ? `${grid.x}` : undefined,
    gridColumnEnd: grid?.cols ? `span ${grid?.cols}` : undefined,
    gridRowStart: grid?.y ? `${grid.y}` : undefined,
    gridRowEnd: grid?.rows ? `span ${grid?.rows}` : undefined,
    justifyItems: grid?.justify_items,
    justifyContent: grid?.justify_content || ((isFlexObject) ? flex.justify_content : undefined),
    ...getBackgroundProperties(background),
    flexDirection: isFlexObject ? flex?.direction : undefined,
    flexShrink: isFlexObject ? flex?.shrink : undefined,
    flexGrow: isFlexObject ? flex?.grow : undefined,
    flexBasis: isFlexObject && (flex?.grow && flex?.grow !== '0') ? 0 : 'auto',
    flexWrap: isFlexObject ? flex?.wrap : undefined,
    ...generateGapStyles(flex),
        // Modified
    ...(isBorderObject ? generateBorderWidth(border) : {}),
    ...(isBorderObject ? generateBorderRadius(border) : {}),
    borderStyle: isBorderObject ? border?.style : undefined,
    borderColor: isBorderObject ? border?.color : undefined,
    border: !isBorderObject ? border : undefined,
    position: position ? `${position as Position} !important` : undefined,
    zIndex: z_index,
    ...getOverflow(scroll, font, display, flex),
    scrollSnapType: scroll?.snap ? 'both mandatory' : undefined,
    boxShadow: box?.shadow ? box?.shadow : undefined,
    boxSizing: box?.sizing ? box?.sizing : 'border-box',
    objectFit: object?.fit,
    objectPosition: object?.position,
    userSelect: user_select,

    left,
    bottom,
    top,
    right,
    transform,
    opacity,
    scrollbarWidth: scroll?.type === 'none' ? 'none' : undefined,
  });

  if (breakpoints) {
    Object.keys(breakpoints).forEach((breakpoint) => {
      const baseWidthForBreakpoint = getSizesBasedOnScreen(breakpoint as ScreenResolutions);
      if (baseWidthForBreakpoint > baseWidth) {
        componentStyles[`@media (min-width: ${baseWidthForBreakpoint}px)`] = {
          ...generateComponentStyles(breakpoints[breakpoint] as Type, undefined, skipStates, display),
        };
      } else if (baseWidthForBreakpoint < baseWidth) {
        componentStyles[`@media (max-width: ${baseWidthForBreakpoint}px)`] = {
          ...generateComponentStyles(breakpoints[breakpoint] as Type, undefined, skipStates, display),
        };
      }
    });
  }

  if (states && !skipStates) {
    Object.keys(states).filter(st => !blockList.includes(st)).forEach((state) => {
      const modifiedState = state === 'focus-within' ? 'focusWithin' : state;
      componentStyles[`&:${modifiedState}`] = {
        ...generateComponentStyles(states[state] as Type, undefined, true, display),
      };
    });
  }

  if (placeholder) {
    componentStyles['&::placeholder'] = placeholderStyles;
  }

  if (scroll?.type === 'none') {
    componentStyles['&::-webkit-scrollbar'] = {
      display: 'none',
    };
  }

  return componentStyles;
};

export default generateComponentStyles;
