const preloadedUrls = new Set<string>();

export const preloadImages = (imageUrls: string[]) => {
  imageUrls.forEach((url) => {
    if (preloadedUrls.has(url)) {
      return;
    }

    const img = new Image();
    img.src = url;
    img.onload = () => {
      // console.log(`Prefetched: ${url}`);
      preloadedUrls.add(url);
    };
    img.onerror = (error) => {
      console.error(`Failed to prefetch: ${url}`, error);
    };
  });
};