interface TextProcessingStrategy {
  processText(text: string, parentWidth: number, textOverflowEllipsis: boolean): string;
}

const measureWidth = (text: string): number => {
  const span = document.createElement('span');
  span.style.visibility = 'hidden';
  span.style.whiteSpace = 'nowrap';
  span.textContent = text;
  document.body.appendChild(span);
  const width = span.offsetWidth;
  document.body.removeChild(span);
  return width;
};

export class SimpleTextProcessor implements TextProcessingStrategy {
  processText(text: string, parentWidth: number, textOverflowEllipsis: boolean): string {
    const tokens = text.split(/(\n)/);
    const fullText = tokens.join('').trim();
    
    if (textOverflowEllipsis) {
      if (measureWidth(fullText) > parentWidth) {
        let start = 0;
        let end = fullText.length;
        while (start < end) {
          const mid = Math.floor((start + end + 1) / 2);
          const testText = fullText.substring(0, mid) + '...';
          if (measureWidth(testText) <= parentWidth) {
            start = mid;
          } else {
            end = mid - 1;
          }
        }
        return `<span class="shimmer-text">${fullText.substring(0, start)}...</span>`;
      }
      return `<span class="shimmer-text">${fullText}</span>`;
    }
    
    let result = '';
    let currentLine = '';
    tokens.forEach((token, i) => {
      if (currentLine && measureWidth(currentLine + token) > parentWidth) {
        result += `<span class="shimmer-text">${currentLine.trim()}</span>${i < tokens.length - 1 ? '<br/>' : ''}`;
        currentLine = token;
      } else {
        currentLine = currentLine ? currentLine + token : token;
      }
    });
    
    if (currentLine) {
      result += `<span class="shimmer-text">${currentLine.trim()}</span>`;
    }
    
    return result;
  }
}

export class ComplexTextProcessor implements TextProcessingStrategy {
  processText(htmlString: string, parentWidth: number, textOverflowEllipsis: boolean): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
  
    function wrapTextNodes(node: Node) {
      if (node.nodeType === Node.TEXT_NODE && node.nodeValue.trim() !== '') {
        const textContent = node.nodeValue.split(/(\s+|\n)/); // Split by spaces and line breaks
        const span = document.createElement('span');
        span.style.visibility = 'hidden';
        span.style.whiteSpace = 'nowrap';
        document.body.appendChild(span);
  
        let currentText = '';
        let wrappedText = '';
        let lineBreaks = 0;
  
        textContent.forEach((token, index) => {
          if (token === '\n') {
            wrappedText += `<span class="shimmer-text">${currentText.trim()}</span><br/>`;
            currentText = '';
            lineBreaks++;
          } else {
            const testText = currentText ? `${currentText}${token}` : token;
            span.textContent = testText;
  
            if (span.offsetWidth > parentWidth) {
              wrappedText += `<span class="shimmer-text">${currentText.trim()}</span><br/>`;
              currentText = token.trim();
              lineBreaks++;
            } else {
              currentText = testText;
            }
          }
  
          if (index === textContent.length - 1 && currentText) {
            wrappedText += `<span class="shimmer-text">${currentText.trim()}</span><br/>`;
          }
        });
  
        document.body.removeChild(span);
        const wrapperSpan = document.createElement('span');
        wrapperSpan.innerHTML = wrappedText;
        (node as ChildNode).replaceWith(wrapperSpan);
  
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        node.childNodes.forEach(wrapTextNodes);
      }
    }
  
    doc.body.childNodes.forEach(node => wrapTextNodes(node));
  
    return doc.body.innerHTML;
  }
}