/* eslint-disable @typescript-eslint/default-param-last */
import { Reducer } from 'redux';
import { PageState, PageActionTypes } from './types';
import merge from 'lodash.merge';

export const initialState: PageState = {
  data: {
    page: {},
  },
  screenId: undefined,
  styleChunks: {},
  sessionData: {},
  settings: {},
  variableData: {},
  formValues: {},
  loading: true,
};

const reducer: Reducer<PageState> = (state = initialState, action) => {
  switch (action.type) {
    case PageActionTypes.PAGE_REQUEST: {
      return { ...state, loading: true, notifications: [] };
    }
    case PageActionTypes.SET_FORM_VALUES_SUCCESS: {
      return { ...state, formValues: { ...state.formValues,  ...action.payload.data } };
    }
    case PageActionTypes.RESET_FORM_VALUES_SUCCESS: {
      return { ...state, formValues: { ...action.payload.data } };
    }
    case PageActionTypes.PAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        previewFile: undefined,
        screenId: action.payload.screenId,
        data: {
          page: action.payload.data,
        },
        settings: { ...action.payload.settings },
        variableData: { ...action.payload.variableData },
        sessionData: { ...action.payload.sessionData },
      };;
    }
    case PageActionTypes.UNCHECK_DEPENDENT_RADIO_SUCCESS: { 
      return {
        ...state,
        data: {
          page: action.payload.data,
        },
      };
    }
    case PageActionTypes.UPDATE_VARIABLE_SUCCESS: {
      return {
        ...state,
        variableData: merge({}, state.variableData, action.payload.data),
      };
    }
    case PageActionTypes.UPDATE_NODE_ACTIVE_STATE_SUCCESS: {
      return {
        ...state,
        data: {
          page: action.payload.data,
        },
      };
    }
    case PageActionTypes.PAGE_ERROR: {
      return { ...state, loading: false };
    }
    case PageActionTypes.CLEAR_FORMS: {
      return { ...state, data: { ...state.data, forms: [] } };
    }
    case PageActionTypes.UPDATE_ITEM_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload },
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as pageReducer };
