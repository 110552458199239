/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import ContainerSkeleton from '../sceleton/ContainerSceleton';
import { replacePlaceholdersWithVariables, replacePlaceholdersWithVariablesV2,  combineClassNames, getSizesBasedOnScreen } from '../../utils/base';
import Checkbox from '../Checkbox';
import Image from '../Image';
import Input from '../Input';
import Radio from '../Radio';
import Select from '../Select';
import Separator from '../Separator';
import Switcher from '../Switcher';
import Text from '../Text';
import moment from 'moment';
import merge from 'lodash.merge';
import { selectVariableData, selectLocalVariableData } from '../../store/page/selectors';
import { getCorrespondingScheme, parseIfJSON, handleOpenUrl, executeFunction, getNestedValue } from '../../utils/base';
import { pageRequest, submitFormRequest, mergeStyleRequest, updateVariableRequest } from '../../store/page/actions';
import { StyleUpdateChunk, RequestUpdateChunk, FunctionUpdateChunk, NodeTypeScheme, Breakpoints } from '../../types/base';
import generateComponentStyles from '../../utils/styleHelper';
import { rowSizeGlobal, colCountGlobal } from '../../config/config';

import style from './Container.module.scss';

const componentMapping = {
  checkbox: Checkbox,
  image: Image,
  input: Input,
  radio: Radio,
  select: Select,
  separator: Separator,
  switcher: Switcher,
  text: Text,
};

const StyledContainer = styled.div`
  ${(props) => generateComponentStyles(props.$shape, props.$baseScreenResolution, props.$skipStates)}
`;

const GridContainerComponent: React.FC<NodeTypeScheme> = (obj) => {
  const {
    id, items, style: stDraft, component,
    events: eventsDraft, dataLake,
    parentIndex,
    pageSettings,
    styleChunks,
    activeState: activeStateDraft,
    nestedTable,
    sceleton: renderSceleton,
  } = obj || {};
  const containerRef = useRef<HTMLDivElement>(null);
  const { style: styleDraft, events, activeState } = dataLake?.data ? replacePlaceholdersWithVariablesV2({ style: stDraft, events: eventsDraft, activeState: activeStateDraft }, dataLake.data) : { style: stDraft, events: eventsDraft, activeState: activeStateDraft };
  // Merge active state styles with base style
  const mergeStylesWithChunks = useCallback((style: any, chunks: any, index: number) => {
    if (chunks && chunks[id] && chunks[id][index] && typeof index === 'number') {
      return merge({}, style, chunks[id][index]);
    }
    return style;
  }, [id]);

  // First useMemo to merge active state styles with base style
  const mergedStyleWithState = useMemo(() => {
    let mergedStyle = styleDraft;
    if (activeState && styleDraft?.states?.[activeState]) {
      mergedStyle = merge({}, styleDraft, styleDraft.states[activeState]);
    }

    // Handle states in breakpoints
    if (styleDraft?.breakpoints) {
      mergedStyle.breakpoints = Object.entries(styleDraft.breakpoints).reduce((acc, [breakpoint, breakpointStyle]) => {
        const typedBreakpointStyle = breakpointStyle as Breakpoints;
        if (typedBreakpointStyle?.states?.[activeState]) {
          acc[breakpoint] = merge({}, typedBreakpointStyle, typedBreakpointStyle.states[activeState]);
        } else {
          acc[breakpoint] = typedBreakpointStyle;
        }
        return acc;
      }, {} as typeof styleDraft.breakpoints);
    }

    return mergedStyle;
  }, [styleDraft, activeState]);

  // Second useMemo to merge styles with chunks based on parentIndex
  const st = useMemo(() => {
    return mergeStylesWithChunks(mergedStyleWithState, styleChunks, parentIndex);
  }, [mergedStyleWithState, styleChunks, parentIndex]);
  const { row: stickyRows } = dataLake?.settings?.pin || {};
  const isSticky = parentIndex < stickyRows;
  const {
    on_click: clickUpdates,
    on_launch: launchUpdates,
    on_hover: hoverUpdates,
    on_unhover: unhoverUpdates,
    on_outclick: outclickUpdates,
  } = events || {};

  const { cols: colCount, rows: rowCount } = st || {};
  const baseScreenResolution =  pageSettings?.baseScreenResolution ? getSizesBasedOnScreen(pageSettings?.baseScreenResolution) : undefined;
  const variableData = useSelector(selectVariableData);
  const localVariableData = useSelector(selectLocalVariableData);
  const dispatch = useDispatch();
  const timeoutsRef = useRef<NodeJS.Timeout[]>([]);

  const internalFns = {
    $setVariable: (key: string, value: any) => {
      dispatch(updateVariableRequest({ variable: key, value }));
    },
    $getVariable: (key: string) => {
      const value = getNestedValue(variableData, key) || getNestedValue(localVariableData, key);
      return value;
    },
    $moment: (...args: any[]) => moment(...args)
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (activeState==='disabled') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    e.preventDefault();
    if (clickUpdates) {
      e.stopPropagation();
      const styleUpdates = clickUpdates.filter((update): update is StyleUpdateChunk => update && 'style' in update);
      const requestUpdates = clickUpdates.filter((update): update is RequestUpdateChunk => update && 'request' in update);
      const functionUpdates = clickUpdates.filter((update): update is FunctionUpdateChunk => update && 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates, parentIndex));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'command') {
            dispatch(pageRequest({ 
              location: { pathname: value }, 
              silent,
              componentId: id,
             }));
          } else if (update?.request && method === 'action') {
            dispatch(submitFormRequest({ value, delay, silent, componentId: id }, stDraft?.states?.loading ? id : undefined));
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }
      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, e, internalFns);
          }
        });
      }
    }
  }

  const handleHover = (e: React.MouseEvent<HTMLDivElement>) => {
    if (activeState === 'disabled' || activeState === 'loading') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (hoverUpdates) {
      const styleUpdates = hoverUpdates.filter((update): update is StyleUpdateChunk => update && 'style' in update);
      const requestUpdates = hoverUpdates.filter((update): update is RequestUpdateChunk => update && 'request' in update);
      const functionUpdates = hoverUpdates.filter((update): update is FunctionUpdateChunk => update && 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates, parentIndex));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'action') {
            dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }
      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, e, internalFns);
          }
        });
      }
    }
  }

  const handleUnhover = (e: React.MouseEvent<HTMLDivElement>) => {
    if (activeState==='disabled') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (unhoverUpdates) {
      const styleUpdates = unhoverUpdates.filter((update): update is StyleUpdateChunk => update && 'style' in update);
      const requestUpdates = unhoverUpdates.filter((update): update is RequestUpdateChunk => update && 'request' in update);
      const functionUpdates = unhoverUpdates.filter((update): update is FunctionUpdateChunk => update && 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates, parentIndex));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'action') {
            dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }
      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, e, internalFns);
          }
        });
      }
    }
  }

  useEffect(() => {
    if (launchUpdates && launchUpdates.length) {
      const styleUpdates = launchUpdates.filter((update): update is StyleUpdateChunk => update && 'style' in update);
      const requestUpdates = launchUpdates.filter((update): update is RequestUpdateChunk => update && 'request' in update);
      const functionUpdates = launchUpdates.filter((update): update is FunctionUpdateChunk => update && 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'action') {
            // const newTimeout = setTimeout(() => {
            //   dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
            // }, config?.delay * 1000 || 0);

            // timeoutsRef.current.push(newTimeout);
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }
      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, undefined, internalFns);
          }
        });
      }
    }

    return () => {
      timeoutsRef?.current?.forEach((timeout) => {
        clearTimeout(timeout);
      });
    };
  }, [launchUpdates]);

  useEffect(() => {
    const handleUnclick = (e: MouseEvent) => {
      if (activeState === 'disabled' || activeState === 'loading') return;
  
      if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
        if (outclickUpdates) {
          const styleUpdates = outclickUpdates.filter((update): update is StyleUpdateChunk => update && 'style' in update);
          const requestUpdates = outclickUpdates.filter((update): update is RequestUpdateChunk => update && 'request' in update);
          const functionUpdates = outclickUpdates.filter((update): update is FunctionUpdateChunk => update && 'function' in update);
  
          if (styleUpdates.length) {
            dispatch(mergeStyleRequest(styleUpdates, parentIndex));
          }
  
          if (requestUpdates.length) {
            requestUpdates.forEach((update) => {
              const { method, value, silent, config } = update?.request || {};
              const { delay } = config || {};
              if (update?.request && method === 'action') {
                dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
              } else if (update?.request && method === 'open-url') {
                handleOpenUrl(value, delay, silent);
              }
            });
          }
  
          if (functionUpdates.length) {
            functionUpdates.forEach((update) => {
              if (update?.function) {
                executeFunction(update.function, undefined, internalFns);
              }
            });
          }
        }
      }
    };
  
    if (outclickUpdates) {
      document.addEventListener('click', handleUnclick);
      return () => {
        document.removeEventListener('click', handleUnclick);
      };
    }
    return undefined;
  }, [parentIndex, activeState, containerRef.current]);

  return (
    <StyledContainer
      ref={containerRef}
      id={`${id}${(nestedTable && typeof parentIndex === 'number') ? `::${parentIndex}` : ''}`}
      style={{
        gridTemplateColumns: `repeat(${colCount || colCountGlobal}, 1fr)`,
        gridTemplateRows: rowCount ? `repeat(${rowCount}, 1fr)` : undefined,
        gridAutoRows: rowCount ? undefined : `${rowSizeGlobal}px`,
        position: isSticky ? 'sticky' : undefined,
        top: isSticky ? 0 : undefined,
        zIndex: isSticky ? `${99999999 - parentIndex}` : undefined,
        visibility: renderSceleton && st ? 'hidden' : undefined,
      }}
      $shape={st}
      $baseScreenResolution={baseScreenResolution}
      $skipStates={activeState === 'disabled' || activeState === 'loading'}
      data-component={component}
      className={combineClassNames(style.class)}
      onClick={handleClick}
      onMouseEnter={handleHover}
      onMouseLeave={handleUnhover}
    >
      {items && items.map((item, index) => {
        const Component = componentMapping[item.component];
        const commonProps = {
          dataLake: getCorrespondingScheme(item, obj, dataLake, index, pageSettings?.variables, parentIndex),
          parentIndex: parentIndex,
          pageSettings,
          styleChunks,
          nestedTable,
          parentStyle: st,
        };

        if (item.component === 'container' && item.type === 'table-body-row' && item.dynamic) {
          const replaced = replacePlaceholdersWithVariables(commonProps?.dataLake?.settings?.source, pageSettings?.variables);
          const parseSourceIfExist = parseIfJSON(replaced);
          return Array.isArray(parseSourceIfExist) ? parseSourceIfExist?.map((sourceItem, sourceIndex) => {
            const dynamicProps = {
              dataLake: getCorrespondingScheme(item, obj, dataLake, sourceIndex, pageSettings?.variables, parentIndex),
              parentIndex: sourceIndex,
              styleChunks,
              nestedTable: true,
            };
            if (Component) {
              return <Component key={`${item.id}::${sourceIndex}`} {...item} {...dynamicProps} parentRef={containerRef} />;
            } if (item.component === 'container') {
              return <GridContainerComponent key={`${item.id}::${sourceIndex}`} {...item} {...dynamicProps} />;
            } if (item.component === 'table') {
              return <GridContainerComponent key={`${item.id}::${sourceIndex}`} {...item} {...dynamicProps} />;
            }
            return null;
          }) : null;
        }

        if (Component) {
          return <Component key={item.id} {...item} {...commonProps} parentRef={containerRef} />;
        } if (item.component === 'container') {
          return <GridContainerComponent key={item.id} {...item} {...commonProps} />;
        } if (item.component === 'table') {
          return <GridContainerComponent key={item.id} {...item} {...commonProps} />;
        }
        return null;
      })}
      {(renderSceleton && st) ? (
        <ContainerSkeleton
          style={st}
          baseScreenResolution={baseScreenResolution}
          skipStates={activeState === 'disabled' || activeState === 'loading'}
          nested={true}
        />
      ) : null}
    </StyledContainer>
  );

}

export default GridContainerComponent;
