import {
  all, call, fork, put, select, takeEvery, takeLatest,
  delay
} from 'redux-saga/effects';
import { PageActionTypes } from './types';
import SessionHelper from '../../utils/sessionHelper';
import merge from 'lodash.merge';
import {
  updateNodeActiveState,
  updateNodeActiveStateSuccess,
  pageRequest,
  pageSuccess,
  clearForms,
  formValuesSuccess,
  resetFormValuesSuccess,
  updateVariableSuccess,
  uncheckDependentRadioSuccess,
  formValuesRequest,
} from './actions';
import { configRequest } from '../config/actions';
import apiRequest from '../../utils/apiReq';
import { 
  setItem as setLocalCache, 
  getItem as getLocalCache,
  removeItem as removeFromLocalCache
 } from '../../utils/indexedDbHelper';
import { 
  recursiveUpdateById, 
  recursivelyUpdateActiveState,
  reccursivelyUncheckRadioItems,
  getDeviceInfo, 
  parseFormValues, 
  replacePlaceholdersWithVariables,
  matchesPattern,
  formCacheKey,
  transformShimmerCache
 } from '../../utils/base';


const parseOldFormat = (data: any) => {
  const { sections } = data || {};
  const [first] = sections || [];
  return { page: first, settings: {}, variableData: {}, screenId: undefined };
}

const parseNewFormat = (data: any) => {
  const { screen, variable_data: variableData } = data || {};
  const { sections, settings, id } = screen || {};
  return { page: sections, settings, variableData, screenId: id };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* handlePageRequest(action: any) {
  const screenId = yield select((state) => state.page.screenId);
  const { payload } = action || {};
  const { location, silent, componentId } = payload || {};
  const { pathname, search } = location || {};

  try {
    const breakLoadPages = ['/not_found', '/frame/internal'];
    if (pathname && !breakLoadPages.includes(pathname)) {
      const page = (pathname === '/' || pathname === '') ? '/' : pathname.replace('/', '');
      yield put(clearForms());

      if (!silent) {
        try {
          const currentPath = window.location.pathname;
          const currentSearch = window.location.search;
          const navigateTo = search ? `${pathname}${search}` : pathname;
                
          if (currentPath !== pathname || 
              (search && currentSearch !== search)) {
            window.history.pushState(null, '', navigateTo);
          }
        } catch (navError) {
          console.error('Navigation failed:', navError);
          // Continue execution even if navigation fails
        }
      }
      const cacheKey = formCacheKey({ command: `${page}${search?.length ? search : ''}`, action: undefined, component_id: componentId, screen_id: screenId });
      const value = yield call(getLocalCache, cacheKey);

      if (value && !silent) {
        const { page: pp, settings, variableData, screenId: screenIdParsed } = value.sections ? parseOldFormat(value) : parseNewFormat(value);
        yield put(pageSuccess({ 
          data: pp, 
          settings, 
          sessionData: {}, 
          variableData,
          screenId: screenIdParsed,
         }));
      }

      const { code, data, sessionData } = yield call(
        apiRequest,
        'post',
        '/get',
        {
          command: `${page}${search?.length ? search : ''}`,
          skip_cache: silent ? true : !!value,
          screen_id: screenId,
          active_route: `${window.location.pathname}${window.location.search || ''}`,
          component_id: componentId,
          device_info: getDeviceInfo(),
          session_id: SessionHelper.getOrCreateSessionId(),
        },
      );
      if (code !== 200 || !data) return;
      const { page: pp, settings, variableData, screenId: screenIdParsed } = data.sections ? parseOldFormat(data) : parseNewFormat(data);
      yield put(pageSuccess({ 
        data: pp, 
        settings, 
        sessionData, 
        variableData,
        screenId: screenIdParsed,
       }));

       const { status, transformed } = transformShimmerCache(data?.screen?.sections);

       if (status && transformed && !silent) {
        // Update response with transformed sections
        // Save
        setLocalCache(cacheKey, {
          ...data,
          screen: {
            ...data.screen,
            sections: transformed,
          },
          variable_data: variableData,
        });
      } else if (!silent) {
        removeFromLocalCache(cacheKey);
      }

          // Handle settings.command URL update
      if (settings?.command) {
        const newCommand = settings.command.replace(/^\//, '');
        const currentPath = window.location.pathname.replace(/^\//, '') + window.location.search;
        
        if (!/\{\{[^}]+\}\}/.test(newCommand) && newCommand !== currentPath) {
          window.history.pushState(null, '', `/${newCommand}`);
        }
      }

      // Parse form values
      const formValues = parseFormValues(pp);
      yield put(resetFormValuesSuccess({ data: formValues }));
    }
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error in handlePageRequest:', error.message);
    }
  }
}

function* handleSetFormValuesRequest(action: any) {
  const { payload } = action || {};
  const { field } = payload || {};
  const { id, value } = field || {};

  yield put(formValuesSuccess({ data: { [id]: value } }));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* sendAllFormValues(action: any) {
  const screenId = yield select((state) => state.page.screenId);
  const { payload, meta } = action || {};
  const { value, delay: del, componentId, block, silent } = payload || {};
  // Stop processing if block is true
  if (block) {
    return;
  }
  const page = yield select((state) => state.page);
  const { settings, variableData, formValues } = page || {};
  const mergedVariables = merge({}, (settings?.variables || {}), variableData || {});
  const replacedFormValue = replacePlaceholdersWithVariables(formValues, mergedVariables);

  yield delay(del|| 0);

  // if (meta) {
  //   yield put(updateNodeActiveState({ id: meta }));
  // }

  const cacheKey = formCacheKey({ command: undefined, action: value, component_id: componentId, screen_id: screenId });
  const value2 = yield call(getLocalCache, cacheKey);

  if (value2 && !silent) {
    const { page: pp, settings, variableData, screenId: screenIdParsed } = value2.sections ? parseOldFormat(value2) : parseNewFormat(value2);
    yield put(pageSuccess({ 
      data: pp,
      settings,
      sessionData: {},
      variableData: variableData,
      screenId: screenIdParsed,
     }));
  }

  try {
    yield put(clearForms());
    const { code, data, sessionData } = yield call(
      apiRequest,
      'post',
      '/get',
      {
        action: value,
        screen_id: screenId,
        skip_cache: silent ? true : !!value,
        active_route: `${window.location.pathname}${window.location.search || ''}`,
        component_id: componentId,
        device_info: getDeviceInfo(),
        session_id: SessionHelper.getOrCreateSessionId(),
        ...replacedFormValue,
      },
    );
    if (code !== 200 || !data) return;
    const { page: pp, settings, variableData, screenId: screenIdParsed } = data.sections ? parseOldFormat(data) : parseNewFormat(data);
    yield put(pageSuccess({ 
      data: pp, 
      settings, 
      sessionData, 
      variableData,
      screenId: screenIdParsed,
     }));

     const { status, transformed } = transformShimmerCache(data?.screen?.sections);

     if (status && transformed && !silent) {
      setLocalCache(cacheKey, {
        ...data,
        screen: {
          ...data.screen,
          sections: transformed,
        },
        variable_data: variableData,
      });
    } else if (!silent) {
      removeFromLocalCache(cacheKey);
    }

    // Handle settings.command URL update
    if (settings?.command) {
      const newCommand = settings.command.replace(/^\//, '');
      const currentPath = window.location.pathname.replace(/^\//, '') + window.location.search;
      
      if (!/\{\{[^}]+\}\}/.test(newCommand) && newCommand !== currentPath) {
        window.history.pushState(null, '', `/${newCommand}`);
      }
    }

    // Parse form values
    const formValues = parseFormValues(pp);
    yield put(resetFormValuesSuccess({ data: formValues }));
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error in sendAllFormValues:', error.message);
    }
  }
}

function* updateVariableRequest(action: any) {
  const { payload } = action || {};
  const { variable, value } = payload || {};
  const keys = variable.split('.');
  const result = keys.reduceRight((acc, key) => ({
    [key]: acc
  }), value);

  yield put(updateVariableSuccess({ data: result }));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* handleLocationChange(action: any) {
  const { location, isFirstRendering } = action.payload;
  if (isFirstRendering === true) {
    yield put(configRequest(location));
  } else {
    yield put(pageRequest({ ...action.payload }));
  }
}

function* handleMergeStyle(action: any) {
  const { payload, meta } = action || {};
  const updates = Array.isArray(payload) ? payload : [payload];
  const { page } = yield select((state) => state.page.data);
  const settings = yield select((state) => state.page.settings);
  const styleChunks = yield select((state) => state.page.styleChunks);
  const variableData = yield select((state) => state.page.variableData);
  const screenId = yield select((state) => state.page.screenId);

  const updatesMap = updates.reduce((map, update) => {
    map[update.object_id] = update;
    return map;
  }, {} as Record<string, any>);

  const inArr = Array.isArray(page) ? page : [page];
  const updatedComponents = recursiveUpdateById(inArr, updatesMap, (item, insideTable) => {
    const update = Object.keys(updatesMap).find((objId) => 
      objId === item.id || (matchesPattern(objId, item.id) && updatesMap[objId]?.object_type === item.component)
    );

    if (update) {
      if (insideTable && typeof meta === 'number') {
        if (!styleChunks[item.id]) {
          styleChunks[item.id] = {};
        }
        styleChunks[item.id][meta] = merge({}, styleChunks[item.id][meta], updatesMap[update]?.style);
        return item;
      } else {
        return {
          ...item,
          style: merge({}, item.style, updatesMap[update]?.style),
        };
      }
    }
    return item;
  });

  yield put(pageSuccess({ 
    data: updatedComponents, 
    settings, 
    variableData, 
    styleChunks,
    screenId: screenId,
   }));
}

function* handleNodeActiveState(action: any) {
  const { payload } = action || {};
  const { id } = payload || {};
  const { page } = yield select((state) => state.page.data);
  const updatedComponents = recursivelyUpdateActiveState(page, id);

  yield put(updateNodeActiveStateSuccess({ data: updatedComponents }));
}

function* handleUncheck(action: any) {
  const { payload } = action || {};
  const { name, excludeId } = payload || {};
  const { page } = yield select((state) => state.page.data);
  const { items, changedIds } = reccursivelyUncheckRadioItems(page, name, excludeId);

  yield put(uncheckDependentRadioSuccess({ data: items }));

  for (const item of changedIds) {
    yield put(formValuesRequest({ field: { id: item, value: false } }));
  }
}

function* watchNodeActiveStatePending() {
  yield takeEvery(PageActionTypes.UPDATE_NODE_ACTIVE_STATE_REQUEST, handleNodeActiveState);
}

function* watchLocationChange() {
  yield takeEvery(PageActionTypes.LOCATION_CHANGE, handleLocationChange);
}

function* watchFetchRequest() {
  yield takeLatest(PageActionTypes.PAGE_REQUEST, handlePageRequest);

  yield takeEvery(PageActionTypes.MERGE_STYLE_REQUEST, handleMergeStyle);
}

function* watchHandleInputRequest() {
  yield takeEvery(PageActionTypes.SET_FORM_VALUES_REQUEST, handleSetFormValuesRequest);
}

function* watchSubmitFormRequest() {
  yield takeLatest(PageActionTypes.SUBMIT_FORM_REQUEST, sendAllFormValues);
}

function* watchUpdateVariableRequest() {
  yield takeLatest(PageActionTypes.UPDATE_VARIABLE_REQUEST, updateVariableRequest);
}

function* watchUncheckDependent() {
  yield takeEvery(PageActionTypes.UNCHECK_DEPENDENT_RADIO_REQUEST, handleUncheck);
}

function* pageSaga() {
  yield all([
    fork(watchNodeActiveStatePending),
    fork(watchHandleInputRequest),
    fork(watchFetchRequest),
    fork(watchSubmitFormRequest),
    fork(watchLocationChange),
    fork(watchUpdateVariableRequest),
    fork(watchUncheckDependent),
  ]);
}

export default pageSaga;